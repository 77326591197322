import HttpClient from "../../HttpClient.js";
import apiRoutes from "../../Routes/Routes.json";

const getPatientDemographicsList = (searchRequest) => {
  return HttpClient.post(
    `api/PatientManagement/GetAllPatientsV2`,
    searchRequest
  );
};

const getPatientRequisitionOrder = (patientId) => {
  return HttpClient.get(
    `api/PatientManagement/Patient/${patientId}/Requisitions`
  );
};

const PatientService = {
  getPatientDemographicsList,
  getPatientRequisitionOrder,
};

export default PatientService;

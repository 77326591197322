import { useState } from "react";
import MiscellaneousService from "../../Services/MiscellaneousManagement/MiscellaneousService";

function LookupsFunctions() {
  const [userLookup, setUserLookup] = useState([]);
  const getUserLookup = async () => {
    let response = await MiscellaneousService.getAllUserLookup();
    setUserLookup(response?.data?.result);
  };
  const notificationTypes = [
    {
      label: "Physician Request",
      value: "Physician Request",
    },
    {
      label: "Supply Request",
      value: "Supply Request",
    },
    {
      label: "Rejected Samples",
      value: "Rejected Samples",
    },
    {
      label: "New sales Rep Request",
      value: "New sales Rep Request",
    },

    {
      label: "Notifiable Diseases",
      value: "Notifiable Diseases",
    },
    {
      label: "New Supply Order Approved",
      value: "New Supply Order Approved",
    },
    {
      label: "Add New Insurance",
      value: "Add New Insurance",
    },

    {
      label: "Add New Facility First Samples",
      value: "Add New Facility First Samples",
    },
    {
      label: "Insurance Pending Approval Request",
      value: "Insurance Pending Approval Request",
    },

    {
      label: "Pickup Scheduled",
      value: "Pickup Scheduled",
    },
    {
      label: "Daily MedArbor Report",
      value: "Daily MedArbor Report",
    },
    {
      label: "Star Labs Daily Report",
      value: "Star Labs Daily Report",
    },
    {
      label: "Un-Validating Order",
      value: "Un-Validating Order",
    },
    {
      label: "Pending PharmD After 100:00 PM EST",
      value: "Pending PharmD After 100:00 PM EST",
    },
    {
      label: "Daily Report Notification",
      value: "Daily Report Notification",
    },
    {
      label: "Issue Responded",
      value: "Issue Responded",
    },
    {
      label: "Incomplete Orders",
      value: "Incomplete Orders",
    },
  ];

  return {
    notificationTypes,
    userLookup,
    getUserLookup,
  };
}

export default LookupsFunctions;

import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DateRangePicker } from "react-date-range";
import "./DatePicker.css";
import formatISO from "date-fns/formatISO";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { CrossIcon } from "../../Icons";
import { DateTimePickerStyling } from "../../../Utils/Common/DateTimePickerStyling";

const DateAndTimeDuplicate = (props) => {
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

 
  const handleClose = () => {
    setOpen(false);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

 
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  const handleDateChange = (item) => {
    setRange([item.selection]);

   
    let dateFrom = formatISO(item?.selection?.startDate);
    let dateTo = formatISO(item?.selection?.endDate);

    const datesObject = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

  
    props.setFilterData(datesObject);
  };


  const inputValue = props.filterData?.dateFrom && props.filterData?.dateTo 
    ? `${props.filterData?.dateFrom} to ${props.filterData?.dateTo}`
    : "yyyy/mm/dd - yyyy/mm/dd";

  return (
    <>
      <div>
        <input
          value={inputValue}
          readOnly
          onClick={() => {
            setOpen(!open);
          }}
          className="form-control bg-white mb-lg-0 h-30px rounded-2 fs-8"
          placeholder="mm/dd/yyyy - mm/dd/yyyy"
        />
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={DateTimePickerStyling}>
            <div ref={refOne}>
              {open && (
                <>
                  <Box onClick={handleClose} className="icon-container">
                    <CrossIcon />
                  </Box>
                  <DateRangePicker
                    orientation={orientation}
                    onChange={handleDateChange}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    initialFocusedRange={[]}
                    months={2}
                    direction="horizontal"
                    maxDate={addDays(new Date(), 0)}
                    showSelectionPreview={true}
                  />
                </>
              )}
            </div>
            <div style={{ textAlign: "end", padding: "0% 2%" }}>
              <button onClick={handleClose} className="btn btn-primary btn-sm">
                Submit
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(DateAndTimeDuplicate);

export enum PortalTypeEnum {
  Admin = 1,
  Facility = 2,
  Sales = 3,
  Patient = 4,
}

export enum UserType {
  Master = 0,
  LabUser = 1,
}

export enum GlobalAccountTypeEnum {
  Username = 0,
  Email = 1,
}

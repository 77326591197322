import React, { useRef, useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { TrashIcon } from "../../Icons";
import { assignFormValues, Decrypt } from "../../../Utils/Auth";
import { useLocation } from "react-router-dom";
import RequisitionType from "../../../Services/Requisition/RequisitionTypeService";
const SignPad = (props: any) => {
  const userTKN = sessionStorage.getItem("userinfo");
  const DECRYPTED_TKN = Decrypt(userTKN);
  const userInfo = JSON.parse(DECRYPTED_TKN);
  const location = useLocation();
  const signCanvas = React.useRef(
    props.sysytemFieldName + props?.controlId
  ) as React.MutableRefObject<any>;
  console.log(props, "props876ysign");

  const clear = () => {
    if (signCanvas.current) {
      props.Inputs[props.index].fields.forEach((Innerfields: any) => {
        if (Innerfields?.systemFieldName === "PhysicianSignature") {
          Innerfields.defaultValue = "";
          props.setCheck(false);
        }
        if (Innerfields?.systemFieldName === "PatientSignature") {
          Innerfields.defaultValue = "";
        }
      });
      props.Inputs[props.index].fields[props.fieldIndex - 1].defaultValue = "";
      props.setInputs([...props.Inputs]);
      signCanvas?.current?.clear();
    }
  };

  useEffect(() => {
    signPadTextAssign(props.padValue);
  }, [props?.padValue]);

  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      inputElementReactSelect.current.focus();
    }
  }, [props?.errorFocussedInput]);
  const PreSelected = async (padVal: any) => {
    signCanvas.current.fromDataURL(padVal);
    if (padVal) {
      let newInputs = await assignFormValues(
        props.Inputs,
        props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        padVal,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      props?.setInputs(newInputs);
    }
  };
  const signPadTextAssign = async (padVal?: any) => {
    signCanvas?.current?.clear();
    // if (
    //   location?.state?.reqId &&
    //   location.state.status !== "Missing Info" &&
    //   !location?.state?.Check
    // ) {
    //   signCanvas?.current?.off();
    // }
    debugger;
    if (
      props.defaultValue &&
      !props?.signatureText &&
      props.defaultValue.length > 200
    ) {
      let image = `data:image/png;base64,${props.defaultValue}`;
      let newInputs = await assignFormValues(
        props.Inputs,
        props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        image,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      props?.setInputs(newInputs);
      setTimeout(() => {
        signCanvas.current.fromDataURL(image);
      });
    }
    const canvas = signCanvas?.current?.getCanvas();
    const ctx = canvas?.getContext("2d");
    ctx.font = "16px Georgia";
    ctx.textAlign = "center";

    if (padVal) {
      var y = 30;
      var digitalSigInfo = padVal.split("!");
      for (let index = 0; index < digitalSigInfo.length; index++) {
        const element = digitalSigInfo[index];
        var vert = y * (index + 1);
        ctx.fillText(element, 250, vert);
        if (index === 1) {
          ctx.beginPath();
          ctx.moveTo(200, 200);
          ctx.lineTo(y * (index + 2), 200);
          ctx.stroke();
        }
      }

      //  ctx.fillText(padVal, 250, 50);
    }
  };
  const handleEndPhysicianSignature = async () => {
    if (props.name === "PhysicianSignature") {
      props.setCheck(false);
    }
    if (signCanvas?.current?.isEmpty()) {
      return;
    }
    const imageBase64 = signCanvas?.current?.toDataURL();
    let newInputs = await assignFormValues(
      props.Inputs,
      props.dependenceyControls,
      props.index,
      props.depControlIndex,
      props.fieldIndex,
      imageBase64,
      props.isDependency,
      props.repeatFieldSection,
      props.isDependencyRepeatFields,
      props.repeatFieldIndex,
      props.repeatDependencySectionIndex,
      props.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    props?.setInputs(newInputs);
  };
  const [phyId, setPhyId] = useState(sessionStorage.getItem("PhysicianID"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentPhyId = sessionStorage.getItem("PhysicianID");
      if (currentPhyId !== phyId) {
        setPhyId(currentPhyId);
      }
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, [phyId]);

  useEffect(() => {
    if (phyId !== "") {
      const fetchPhysicianSignature = async () => {
        const obj = {
          user_id: userInfo?.userId,
          physician_id: phyId,
        };
        try {
          const res = await RequisitionType.GetPhysicianSignature(obj);
          if (props.name === "PhysicianSignature") {
            if (res?.data) PreSelected(res.data);
            if (!location?.state?.reqId && !res?.data) clear();
          }
        } catch (error) {
          console.error("Failed to fetch physician signature", error);
        }
      };
      fetchPhysicianSignature();
    }
  }, [phyId]);
  return (
    <>
      <div id={props?.name} ref={inputElementReactSelect} tabIndex={-1}>
        {" "}
      </div>
      <div
        style={{ backgroundColor: "#F3F6F9" }}
        className={`h-150px ${
          props.Inputs[props.index].fields[props.fieldIndex - 1]
            ?.defaultValue && props.sectionName === "Physician Signature"
            ? "inactiveLink"
            : ""
        }`}
      >
        <SignaturePad
          ref={signCanvas}
          canvasProps={{
            className: "h-100 w-100",
          }}
          penColor="black"
          onEnd={handleEndPhysicianSignature}
        />
      </div>
      {props.error && (
        <div className="form__error">
          <span>{props.error}</span>
        </div>
      )}
      <button
        onClick={clear}
        type="button"
        className=" mt-3 mb-3 btn btn-primary btn-sm px-4 mx-2 p-2"
        // disabled={
        //   (location?.state?.reqId &&
        //     location.state.status === "Missing Info") ||
        //   (location?.state?.reqId && location?.state?.Check) ||
        //   !location?.state?.reqId
        //     ? false
        //     : true
        // }
      >
        <TrashIcon />
        Clear
      </button>
    </>
  );
};

export default SignPad;

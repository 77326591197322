export const DateTimePickerStyling = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 510,
  bgcolor: "background.paper",
  boxShadow: 24,
};

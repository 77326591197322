import { useLocation } from "react-router-dom";
import MuiSkeleton from "../MuiSkeleton";
import { useEffect, useState } from "react";
const Checkbox = (props: any) => {
  const location = useLocation();
  const [checked, setChecked] = useState<any>(
    location?.state?.reqId ? props.checked : false
  );
  useEffect(() => {
    setChecked(location?.state?.reqId ? props.checked : false);
  }, [props?.apiCallCondition]);
  const SplitStringByDollarSign = (inputString: any) => {
    const splitIndex = inputString.indexOf("$");
    if (splitIndex === -1) {
      return <span>{inputString}</span>;
    } else {
      const part1 = inputString.substring(0, splitIndex);
      const part2 = inputString.substring(splitIndex + 1);
      return (
        <>
          <span>{part1}</span> <br />
          <span className="text-muted">{part2}</span>
        </>
      );
    }
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked); // Update checked state to true/false
    props.onChange(event); // Call parent's onChange handler if provided
  };
  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-4`
            : "col-lg-6 col-md-6 col-sm-12 mb-4"
        }
      >
        <div className="form__group form__group--checkbox">
          <label
            className={
              props?.labelClassName
                ? `${props?.labelClassName} fw-500 ` + `${props?.required}`
                : "form-check form-check-inline form-check-solid m-0 fw-500" +
                  `${props?.required}`
            }
          >
            {props.sectionName === "Diagnosis/ICD10 Codes" ? (
              <input
                className="form-check-input h-20px w-20px"
                type="checkbox"
                id={props.id}
                name={props.label}
                value={props.value}
                onChange={props.onChange}
                defaultChecked={props.checked}
                checked={props.checked}
                disabled={props?.disabled}
              />
            ) : props.sectionId === 17 ||
              props.sectionId === 35 ||
              props.sectionId === 21 ? (
              <>
                {" "}
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  id={props.id}
                  name={props.label}
                  value={props.value}
                  onChange={props.onChange}
                  defaultChecked={props.defaultValue === "True" ? true : false}
                  checked={props.checked}
                  disabled={props?.disabled}
                />
              </>
            ) : props.sectionName === "Patient Signature" ? (
              <>
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  id={props.id}
                  name={props.label}
                  value={btoa(props.value)}
                  onChange={props.onChange}
                  defaultChecked={props.checked}
                  // checked={props.checked}
                  disabled={props?.disabled}
                />
              </>
            ) : (
              <input
                className="form-check-input h-20px w-20px"
                type="checkbox"
                id={props.id}
                name={props.label}
                value={props.value}
                onChange={handleCheckboxChange}
                checked={checked}
                disabled={props?.disabled}
              />
            )}

            {props?.loading ? (
              <MuiSkeleton height={22} />
            ) : (
              <span
                className={
                  props?.spanClassName
                    ? `${props.spanClassName} text-break fw-400${
                        props.required ? " required" : ""
                      }`
                    : ""
                }
              >
                {props?.testCode ? props?.testCode + `:` : ""}
                {SplitStringByDollarSign(props.label)}
              </span>
            )}
          </label>
          {props.error && <div className="form__error">{props.error}</div>}
        </div>
      </div>
    </>
  );
};

export default Checkbox;

import React, { useEffect, useRef } from "react";
import { getToxTestingOption } from "../../../Utils/Common/Requisition";

const Radio2 = (props: any) => {
  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      const defaultSelectedOption = props?.RadioOptions?.find(
        (choice: any) => choice?.isSelectedDefault
      );
      if (!defaultSelectedOption) {
        inputElementReactSelect.current.focus();
      }
    }
  }, [props?.errorFocussedInput]);

  let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
  let toxtestingoptionindex = getToxTestingOption(inputsCopy);

  useEffect(() => {
    const defaultSelectedOption = props?.RadioOptions?.find(
      (choice: any) => choice?.isSelectedDefault
    );
    const e = {
      target: {
        value: defaultSelectedOption?.value,
        attributes: {
          "data-name": { value: props?.systemFieldName },
          "data-id": { value: defaultSelectedOption?.id },
        },
        checked: false,
      },
    };
    if (defaultSelectedOption) {
      props?.onChange(
        e,
        defaultSelectedOption.value,
        defaultSelectedOption.value,
        defaultSelectedOption?.label
      );
    }
  }, []);

  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-5`
            : "col-lg-6 col-md-6 col-sm-12 mb-5 "
        }
        id={props?.name}
        ref={inputElementReactSelect}
        tabIndex={-1}
      >
        <label
          className={`d-block mb-2 fw-500 text-dark${
            props.required ? " required" : ""
          }`}
        >
          {props?.label}
        </label>

        <div className="row g-2">
          {props?.RadioOptions?.map(
            (choice: any) =>
              choice?.isVisable && (
                <label
                  key={choice?.id}
                  className="col-6 fw-400 d-flex justify-content-start align-items-start"
                  htmlFor={choice?.name + choice?.id}
                  id={choice?.name + choice?.id}
                >
                  <input
                    className="form-check-input ifuser flex-column-auto h-20px w-20px"
                    type="radio"
                    name={choice?.name + props?.repeatFieldIndex + choice?.id}
                    id={choice?.id}
                    data-name={choice?.name}
                    data-id={choice?.id}
                    value={choice?.value}
                    checked={
                      props.defaultValue
                        ? choice?.value.split(" ").join("") ===
                          props?.defaultValue.split(" ").join("")
                        : choice?.isSelectedDefault
                    }
                    defaultChecked={choice.isSelectedDefault ? true : false}
                    onChange={(e: any) => {
                      // for tox testing option on the basis of specimen type
                      if (
                        (props?.Rname === "Tox" ||
                          props?.Rname === "Tox Requisition") &&
                        props.sectionName === "Specimen Information" &&
                        props.systemFieldName === "SpecimenType"
                      ) {
                        props?.Inputs[toxtestingoptionindex]?.fields?.forEach(
                          (i: any) => {
                            i?.panels?.forEach((j: any) => {
                              j.testOptions.forEach((k: any) => {
                                if (k.specimenType === choice.label) {
                                  i.isVisible = true;
                                } else {
                                  i.isVisible = false;
                                  i.isSelected = false;
                                  j.isSelected = false;
                                }
                              });
                            });
                          }
                        );
                      }
                      props?.onChange(
                        e,
                        e.target.value,
                        choice?.value,
                        choice?.label
                      );
                    }}
                    disabled={props?.disabled}
                    required={props.required}
                  />
                  <span className="ps-2 text-break">{choice?.label}</span>
                </label>
              )
          )}
        </div>
        {props.error && (
          <div className="form__error">
            <span>{props.error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Radio2);

import React from "react";
import { Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import {
  StyledDropButton,
  StyledDropButtonThreeDots,
  StyledDropMenu,
  StyledDropMenuMoreAction,
} from "../../../Utils/Style/Dropdownstyle";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import PermissionComponent from "../../../Shared/Common/Permissions/PermissionComponent";
import useLang from "Shared/hooks/useLanguage";
interface Props {
  facilityUserList: any;
  selectedBox: any;
  tabKey: any;
  onFacilityStatusChange: any;
  item: any;
  changeStatus: any;
}
export function extractDatePattern(dateTimeString: any) {
  const parts = dateTimeString.split("T");
  if (parts.length === 2) {
    const datePart = parts[0];
    const timePart = parts[1].substring(0, 8);

    const dateParts = datePart.split("-");
    if (dateParts.length === 3) {
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];

      return `${month}/${day}/${year} ${timePart}`;
    }
  }
  return dateTimeString;
}

export function ConvertUTCTimeToLocal(dateTimeString: any) {
  var time = moment(dateTimeString).utc();
  var localtime = time.local().format("MM/DD/yyyy hh:mm:ss A");
  return localtime;
}
export function ConvertUTCDate(dateTimeString: any) {
  var time = moment(dateTimeString);
  var formatedDate = time.format("MM/dd/yyyy");
  return formatedDate;
}
const FacilityListExpandableTable: React.FC<Props> = ({
  facilityUserList,
  selectedBox,
  tabKey,
  onFacilityStatusChange,
  item,
  changeStatus,
}) => {
  const { t } = useLang()
  const [open, setOpen] = React.useState(false);
  let id = item.facilityId;

  // *********** Dropdown Function START ***********
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDrop = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // *********** Dropdown Function End ***********
  const [value, setValue] = React.useState<any>(null);
  const [status, setStatus] = React.useState<any>("");
  const ModalhandleClose1 = () => {
    setShow1(false);
    setValue(null);
    setStatus("");
  };
  const [show1, setShow1] = React.useState(false);
  const handleClickOpen = (userid: any, status: any) => {
    setShow1(true);
    setValue(userid);
    setStatus(status);
  };
  return (
    <>
      <Modal
        show={show1}
        onHide={ModalhandleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-light-primary m-0 p-5">
          {status === "isapproved" ? <h4>{t("Approval")}</h4> : <h4>{t("Rejected")}</h4>}
        </Modal.Header>

        {status === "isapproved" ? (
          <Modal.Body>
            {t("Are you sure you want to approve this facility ?")}
          </Modal.Body>
        ) : (
          <Modal.Body>
            {t("Are you sure you want to reject this facility ?")}
          </Modal.Body>
        )}
        <Modal.Footer className="p-0">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={ModalhandleClose1}
          >
            {t("No")}
          </button>
          <button
            type="button"
            className="btn btn-danger m-2"
            onClick={() => {
              changeStatus(value, status);
              ModalhandleClose1();
            }}
          >
            {t("Yes")}
          </button>
        </Modal.Footer>
      </Modal>
      <TableRow>
        <>
          {tabKey === 0 ? (
            <>
              <TableCell className="text-center">
                <StyledDropButtonThreeDots
                  id="demo-positioned-button"
                  aria-controls={openDrop ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDrop ? "true" : undefined}
                  onClick={handleClick}
                  className="btn btn-light-info btn-sm btn-icon moreactions min-w-auto rounded-4"
                >
                  <i className="bi bi-three-dots-vertical p-0 icon"></i>
                </StyledDropButtonThreeDots>
                <StyledDropMenuMoreAction
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={openDrop}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <PermissionComponent
                    pageName="Facility Request"
                    permissionIdentifier="View"
                  >
                    <MenuItem onClick={handleClose} className="w-100px p-0">
                      <Link
                        className="text-dark w-100 h-100"
                        to={`/facility-view-approval/${btoa(
                          item?.facilityId
                        )}`}
                      >
                        <i className="fa fa-eye text-success mr-2 w-20px"></i>
                        {t("View")}
                      </Link>
                    </MenuItem>
                  </PermissionComponent>
                  <PermissionComponent
                    pageName="Facility Request"
                    permissionIdentifier="Approve"
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpen(item.facilityId, "isapproved");
                      }}
                      className=" w-100px"
                    >
                      <i
                        className="fa fa-check text-success mr-2  w-20px"
                        aria-hidden="true"
                      ></i>
                      {t("Approve")}
                    </MenuItem>
                  </PermissionComponent>
                  <PermissionComponent
                    pageName="Facility Request"
                    permissionIdentifier="Rejected"
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpen(item.facilityId, "rejected");
                      }}
                      className=" w-100px"
                    >
                      <i className="fa-solid fa-ban text-danger mr-2  w-20px"></i>
                      {t("Rejected")}
                    </MenuItem>
                  </PermissionComponent>
                </StyledDropMenuMoreAction>
              </TableCell>
            </>
          ) : null}
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.facilityName}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.phone}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.primaryContactName}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.primaryContactEmail}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.address1}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.address2}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.city}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.zipCode}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.submittedBy}</TableCell>
          <TableCell sx={{ width: 'max-content', whiteSpace: 'nowrap' }}>{item?.submittedDate}</TableCell>
        </>
      </TableRow>
    </>
  );
};
export default FacilityListExpandableTable;

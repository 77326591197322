import { useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param onLogout - function to logout user after idle time
 * @param idleTime - number of seconds to wait before user is logged out
 */

const useIdleTimeout = ({ onIdle, onLogout, idleTime }: any) => {
  const idleTimeout = 1200000; // idleTime in milliseconds
  const logoutTimeoutRef = useRef<any>(null); // Ref to store the logout timeout ID
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    onIdle();
    clearTimeout(logoutTimeoutRef.current); // Clear any existing timeout
    logoutTimeoutRef.current = setTimeout(() => {
      onLogout(); // Logout after an additional 5 minutes
    }, idleTime); // 5 minutes in milliseconds
  };

  const { reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
  });

  // Function to reset both idle timer and logout timeout
  const resetAll = () => {
    reset();
    clearTimeout(logoutTimeoutRef.current);
    setIdle(false);
  };

  return {
    isIdle,
    setIdle,
    reset: resetAll,
  };
};

export default useIdleTimeout;

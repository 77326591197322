import Files from "./SectionsUi/Files";
import RequisitionFields from "./SectionsUi/RequisitionFields";
import SignatureText from "./SectionsUi/SignatureText";
export const renderSwitch = (props: any) => {
  const { sectionDisplayName, fieldsInfo } = props;
  switch (true) {
    case sectionDisplayName === "Files":
      return <Files fieldsInfo={fieldsInfo} />;
    case sectionDisplayName === "Physician Signature" ||
      sectionDisplayName === "Patient Signature":
      return <SignatureText fieldsInfo={fieldsInfo} />;
    case sectionDisplayName !== "Files" ||
      sectionDisplayName !== "Requisition" ||
      sectionDisplayName !== "Physician Signature" ||
      sectionDisplayName !== "Patient Signature":
      return <RequisitionFields fieldsInfo={fieldsInfo} />;
    default:
      return "";
  }
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useLogoutListener = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "logout") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate, dispatch]);

  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("logout", Date.now().toString());

    // Redirect to login page
    window.location.href = "/login";
  };

  return logout;
};

export default useLogoutListener;

export const inputs = [
  {
    name: "specimenType",
    type: "text",
    placeholder: "Specimen Type",
    label: "Specimen Type",
  },
];
export const PanelTypeInputs = [
  {
    name: "name",
    type: "text",
    placeholder: "Panel Type",
    errorMessage: "",
    label: "Panel Type",
    required: true,
  },
];
export const PanelSetUpInputs = [
  {
    name: "name",
    type: "text",
    placeholder: "Panel Name",
    errorMessage: "",
    label: "Panel Name",
    required: true,
  },
  {
    name: "displayName",
    type: "text",
    placeholder: "Display Name",
    errorMessage: "",
    label: "Display Name",
    required: true,
  },
  {
    name: "tmiT_Code",
    type: "text",
    placeholder: "TMIT Code",
    errorMessage: "",
    label: "TMIT Code",
    required: true,
  },
];
export const PanelGroupInputs = [
  {
    name: "name",
    type: "text",
    placeholder: "Group Name",
    errorMessage: "",
    label: "Group Name",
    required: true,
  },
  {
    name: "displayName",
    type: "text",
    placeholder: "Display Name",
    errorMessage: "",
    label: "Display Name",
    required: true,
  },
];
export const TestTypeInputs = [
  {
    name: "testType",
    type: "text",
    placeholder: "Test Type",
    errorMessage: "",
    label: "Test Type",
    required: true,
  },
];
export const TestSetUpInputs = [
  {
    name: "testName",
    type: "text",
    placeholder: "Test Name",
    errorMessage: "",
    label: "Test Name",
    required: true,
  },
  {
    name: "testDisplayName",
    type: "text",
    placeholder: "Display Name",
    errorMessage: "",
    label: "Display Name",
    required: true,
  },
  {
    name: "tmitcode",
    type: "text",
    placeholder: "TMIT Code",
    errorMessage: "",
    label: "TMIT Code",
    required: true,
  },
];
export const UserManagmentInputs = [
  {
    name: "firstName",
    type: "text",
    placeholder: "Test Name",
    errorMessage: "",
    label: "Test Name",
    required: true,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Display Name",
    errorMessage: "",
    label: "Display Name",
    required: true,
  },
  {
    name: "adminEmail",
    type: "text",
    placeholder: "TMIT Code",
    errorMessage: "",
    label: "TMIT Code",
    required: true,
  },
];

export const AddFacilityUserInputs = [
  {
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    errorMessage: "",
    label: "First Name",
    required: true,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    errorMessage: "",
    label: "Last Name",
    required: true,
  },
  {
    name: "npi",
    type: "text",
    placeholder: "NPI #",
    errorMessage: "",
    label: "NPI #",
    required: true,
  },
  {
    name: "stateLicenseNo",
    type: "text",
    placeholder: "State License #",
    errorMessage: "",
    label: "State License #",
    required: true,
  },
  {
    name: "phone",
    type: "text",
    placeholder: "Phone No",
    errorMessage: "",
    label: "Phone No",
    required: true,
  },
  // {
  //   name: "firstName",
  //   type: "text",
  //   placeholder: "User Title",
  //   errorMessage:"",
  //   label: "User Title",

  // },
];

export const GroupSetupInputs = [
  {
    name: "name",
    type: "text",
    placeholder: "Group Name",
    errorMessage: "",
    label: "Group Name",
    required: true,
  },
  {
    name: "description",
    type: "text",
    placeholder: "Display Name",
    errorMessage: "",
    label: "Display Name",
    required: true,
  },
];

import { PureAbility } from "@casl/ability";
import { getTokenData } from "../../../Utils/Auth";
import { GetSingleElementFromArray } from "../../../Utils/Common/CommonMethods";
let permissions: any = [];
const userInfo = getTokenData();
if (
  typeof userInfo !== "undefined" &&
  typeof userInfo.authTenants !== "undefined"
) {
  let selectedTenantInfo = GetSingleElementFromArray(
    userInfo.authTenants,
    (x: any) => x.isSelected === true
  );
  permissions = selectedTenantInfo?.infomationOfLoggedUser?.permissions;
}
const ability = new PureAbility(permissions);
export default ability.update(permissions);

import {
  TableCell,
  TableRow
} from "@mui/material";
import moment from "moment";

function Row(props: { row: any }) {
  const { row } = props;
  return (
    <>
      <TableRow className="h-30px">
        <TableCell sx={{ width: "max-content" }}>
          <span>{row?.facilityName}</span>
        </TableCell>

        <TableCell sx={{ width: "max-content" }}>
          {row?.trackingNumber}
        </TableCell>

        <TableCell>{moment(row?.dateofScan).format("MM-DD-YYYY")}</TableCell>
        <TableCell sx={{ width: "max-content" }}>{row?.status}</TableCell>
      </TableRow>
    </>
  );
}

export default Row;

// AbilityContext.tsx
import { createContext, useContext } from "react";
import ability from "./Abilities";

export const AbilityContext = createContext(ability);
export const AbilityProvider: React.FC = ({ children }: any) => {
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = () => useContext(AbilityContext);

import { Decrypt, Encrypt } from "../Auth";

export const GetElementsFromArray = (array: any[], condition: any) => {
  return array.filter(condition);
};
export const GetSingleElementFromArray = (array: any[], condition: any) => {
  return array.find(condition);
};
export const checkPermissions = (array: any[], module: any) => {
  return array.some((i: any) => {
    return i.claims.some((j: any) => {
      return j.name === module;
    });
  });
};

/**
 * Function for setting a value into sessionStorage with encryption.
 * @param key - The key under which the value will be stored.
 * @param value - The value to be stored. It can be any type.
 */
export const setValueIntoSessionStorage = (key: string, value: any) => {
  if (value === undefined || value === null) return;

  try {
    const stringValue = JSON.stringify(value);
    const encryptedValue = Encrypt(stringValue);
    sessionStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error(
      `Error storing value in sessionStorage for key "${key}":`,
      error
    );
  }
};

/**
 * Function for retrieving a value from sessionStorage with decryption.
 * @param key - The key under which the value is stored.
 * @returns The decrypted value or null if not found or if an error occurs.
 */
export const getValueFromSessionStorage = (key: string): any | null => {
  try {
    const encryptedValue = sessionStorage.getItem(key);
    if (!encryptedValue) return null;

    const decryptedValue = Decrypt(encryptedValue);
    return JSON.parse(decryptedValue);
  } catch (error) {
    console.error(
      `Error retrieving or parsing value from sessionStorage for key "${key}":`,
      error
    );
    return null;
  }
};

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Paper } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ConvertUTCTimeToLocal,
  extractDatePattern,
} from "../../../Facility/FacilityApproval/FacilityListExpandableTable";
import { isJson } from "../../../../Utils/Common/Requisition";
import { Link } from "react-router-dom";
import { savePdfUrls } from "../../../../Redux/Actions/Index";
import { useDispatch } from "react-redux";
const theme = createTheme({
  palette: {
    primary: {
      main: "#4CAF50",
    },
  },
});
const tabStyles = {
  textTransform: "capitalize",
  fontWeight: "bold",
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Files = (props: any) => {
  const dispatch = useDispatch();
  const { fieldsInfo } = props;
  const result = isJson(fieldsInfo.fieldName);
  let filesData = fieldsInfo.fieldName;
  if (result) {
    filesData = JSON.parse(filesData);
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs aria-label="basic tabs example">
            <Tab label="Uploaded Files" sx={tabStyles} />
          </Tabs>
        </Box>
        <CustomTabPanel value={0} index={0}>
          <Box sx={{ height: "auto", width: "100%" }}>
            <div className="table_bordered overflow-hidden">
              <TableContainer
                sx={{
                  maxHeight: "calc(100vh - 100px)",
                  "&::-webkit-scrollbar": {
                    width: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#fff",
                  },
                  "&:hover": {
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "var(--kt-gray-400)",
                      borderRadius: 2,
                    },
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "var(--kt-gray-400)",
                    borderRadius: 2,
                  },
                }}
                component={Paper}
                className="shadow-none"
              >
                <Table
                  aria-label="sticky table collapsible"
                  className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-1"
                >
                  <TableHead className="h-40px">
                    <TableRow>
                      <TableCell className="min-w-300px w-300px">
                        File
                      </TableCell>
                      <TableCell className="min-w-150px w-150px">
                        Document Type
                      </TableCell>
                      <TableCell className="min-w-150px w-150px">
                        Date & Time
                      </TableCell>
                      <TableCell className="min-w-150px w-150px">
                        Email
                      </TableCell>
                      <TableCell className="min-w-150px w-150px">
                        Resend Result
                      </TableCell>
                      <TableCell className="min-w-150px w-150px">SMS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesData?.map((p: any) => (
                      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                          <Link
                            to={`/docs-viewer`}
                            target="_blank"
                            onClick={() => {
                              dispatch(savePdfUrls(p.FileUrl));
                            }}
                          >
                            {p.FileName}
                          </Link>
                        </TableCell>
                        <TableCell>{p.TypeOfFile}</TableCell>
                        <TableCell>
                          {ConvertUTCTimeToLocal(p.CreatedDate)}
                        </TableCell>
                        <TableCell>
                          {/* <a
                            href={p.FilePath}
                            className="btn btn-sm fw-bold btn-info rounded-3"
                          >
                            <span>Resend &amp; Email</span>
                          </a> */}
                        </TableCell>
                        <TableCell>
                          {/* <a
                            href={p.FilePath}
                            className="btn btn-success btn-sm fw-bold search d-block"
                          >
                            <span  >
                              Resend &amp; Fax
                            </span>
                          </a> */}
                        </TableCell>
                        <TableCell>
                          {/* <a
                            href={p.FilePath}
                            className="btn btn-success btn-sm fw-bold search d-block"
                          >
                            <span  >
                              Resend &amp; SMS
                            </span>
                          </a> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </CustomTabPanel>
      </Box>
    </ThemeProvider>
  );
};

export default Files;

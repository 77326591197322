import CircularProgress from "@mui/material/CircularProgress";

export const Loader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <CircularProgress style={{ color: "#69A54B" }} />
  </div>
);


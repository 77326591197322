import React, { useEffect, useRef, useState } from "react";
import Checkbox from "./Checkbox";
import { assignFormValues } from "../../../Utils/Auth";
import { isJson } from "../../../Utils/Common/Requisition";

const PanelsCheckBox = (props: any) => {
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<any>([]);
  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [props.defaultValue.defaultValue]);
  const inputElement = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput == inputElement.current ||
      props?.errorFocussedInput == inputElement.current.id
    ) {
      inputElement.current.focus();
    }
  }, [props?.errorFocussedInput]);
  const convertPrefilledIcdDataToJson = () => {
    let result = isJson(props.defaultValue.defaultValue);
    let parsedIcd10Codes = props.defaultValue.defaultValue;
    if (result) {
      parsedIcd10Codes = JSON.parse(props.defaultValue.defaultValue);
    }
    let objectKeysConvertedToSmall =
      Array.isArray(parsedIcd10Codes) &&
      parsedIcd10Codes?.map((icd10Codeinfo: any) => {
        return {
          id: icd10Codeinfo.icd10id,
          icD10Code: icd10Codeinfo.Code,
          icD10CodeDescription: icd10Codeinfo.Description,
        };
      });

    //IN CASE IT WILL GIVE INFINE LOOP
    let newInputs = assignFormValues(
      props?.Inputs,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      parsedIcd10Codes,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    newInputs?.then((res) => {
      let infectiousDataCopy = JSON?.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });

    let checboxIds =
      Array.isArray(parsedIcd10Codes) &&
      parsedIcd10Codes?.map((icd10Codeinfo: any) => icd10Codeinfo.Code);
    setDiagnosisCode(
      Array.isArray(objectKeysConvertedToSmall)
        ? objectKeysConvertedToSmall
        : []
    );
    setCheckedBoxes(Array.isArray(checboxIds) ? checboxIds : []);
  };
  //convertPrefilledIcdDataToJson();
  const handleChange = (
    id: string,
    code: string,
    description: string,
    checked: boolean
  ) => {
    props.setCheckboxState((prevState: any) => {
      const newState = prevState.map((item: any) => {
        if (item.testCode === code) {
          return { ...item, isChecked: checked };
        }
        return item;
      });
      return newState;
    });
    if (checked) {
      let obj = {
        id,
        icD10Code: code,
        icD10CodeDescription: description,
      };
      setDiagnosisCode((prev: any) => [...prev, obj]);
      setCheckedBoxes((prev: any) => [...prev, code]);
      let inputValue = [];
      inputValue.push({
        Code: code,
        Description: description,
        icd10id: id,
      });
      let defaultValue = props.defaultValue.defaultValue;
      if (isJson(defaultValue)) {
        defaultValue = JSON.parse(defaultValue);
      }
      if (defaultValue.length > 0) {
        inputValue = [...inputValue, ...defaultValue];
      }
      setDiagnosisCode([...inputValue]);
      assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      ).then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    } else {
      setDiagnosisCode((prev: any) =>
        prev.filter((item: any) => item.icD10Code !== code)
      );
      setCheckedBoxes((prev: any) => prev.filter((item: any) => item !== code));
      let defaultValue = props.defaultValue.defaultValue;
      if (isJson(defaultValue)) {
        defaultValue = JSON.parse(defaultValue);
      }
      let inputValue = defaultValue.filter(
        (checkedPanelsObj: any) => checkedPanelsObj.Code !== code
      );
      assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      ).then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{props.error}</span>
        </div>
      )}
      <div id={props?.name} ref={inputElement} tabIndex={-1}></div>
      <div className={props?.sectionDisplayType}>
        <div className="d-flex flex-wrap">
          {props?.panels?.map(
            (panels: any) =>
              panels?.isVisible && (
                <div
                  className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                >
                  <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h6>{panels?.panelName}</h6>
                    </div>
                  </div>
                  <div className="card-body py-md-4 py-3">
                    <div className="row">
                      {panels?.testOptions?.map((options: any, index: any) => (
                        <>
                          <Checkbox
                            spanClassName="mb-2 mr-2"
                            parentDivClassName="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                            id={options?.testID}
                            label={options.testName}
                            testCode={options?.testCode}
                            checked={
                              props.sectionName === "Diagnosis/ICD10 Codes"
                                ? props.checkboxState.find(
                                    (item: any) =>
                                      item.testCode === options?.testCode
                                  )?.isChecked
                                : options?.isSelected
                            }
                            onChange={(e: any) => {
                              handleChange(
                                options?.testID,
                                options?.testCode,
                                options?.testName,
                                e.target.checked
                              );
                            }}
                            sectionName={props.sectionName}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PanelsCheckBox;

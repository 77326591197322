import React, { useEffect, useRef, useState } from "react";
import MuiSkeleton from "../MuiSkeleton";
import UseDrugAllergiesAutoComplete from "../../../Shared/hooks/Requisition/UseDrugAllergiesAutoComplete";
import { isArray } from "lodash";
import { assignFormValues } from "../../../Utils/Auth";
import { useLocation } from "react-router-dom";
const CheckBoxList = (props: any) => {
  useEffect(() => {
    EditDrugAllergies();
  }, [props.defaultValue]);
  const [selectedDrugAllergies, setSelectedDrugAllergiesBox] = useState<any>(
    props?.defaultValue ? props?.defaultValue : []
  );
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>(
    props.defaultValue
      ? Object.fromEntries(
          props?.defaultValue.map((option: any) => [option.id, true])
        )
      : Object.fromEntries(
          props?.RadioOptions.map((option: any) => [option.id, false])
        )
  );
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [event, setEvent] = React.useState<any>();
  const {
    searchedValue,
    setSearchedValue,
    setTouched,
    setActiveSuggestion,
    setSelectedSuggestion,
    suggestions,
    setSuggestions,
    selectedSuggestion,
    activeSuggestion,
    loading,
    handleChange,
    handleKeyDown,
    handleClick,
    touched,
  } = UseDrugAllergiesAutoComplete(inputSearchRef);
  const EditDrugAllergies = () => {
    if (props.sysytemFieldName === "DrugAllergies") {
      assignFormValues(
        props.Inputs,
        props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        props.defaultValue,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
    }
  };
  const removeDrugAllergy = (id: number, label: any) => {
    let inputsCopy = [...props?.Inputs];
    let index = props?.index;
    if (index) {
      let indexDrugs = inputsCopy[index]?.fields?.findIndex(
        (i: any) => i?.systemFieldName === "DrugAllergies"
      );
      inputsCopy[index].fields[indexDrugs].defaultValue = inputsCopy[
        index
      ].fields[indexDrugs]?.defaultValue?.filter(
        (innerDrug: any) => innerDrug.label !== label
      );
    }
    setSelectedDrugAllergiesBox((prevAllergies: any) => {
      const updatedArray = [...prevAllergies];
      const indexToRemove = updatedArray.findIndex(
        (item: any) => item.id === id
      );
      if (indexToRemove !== -1) {
        updatedArray.splice(indexToRemove, 1);
      }
      setCheckboxState((prevState) => {
        const newCheckboxState = { ...prevState };
        newCheckboxState[id] = false;
        return newCheckboxState;
      });
      const modifiedEvent = {
        target: {
          checked: false,
          id,
        },
      };
      props.onChange(modifiedEvent, id, label);
      return updatedArray;
    });
  };
  let isVisible: any;
  // eslint-disable-next-line array-callback-return
  props.Inputs[props.index].fields.map((i: any) => {
    if (i.systemFieldName === "DrugOthres") {
      isVisible = i.visible;
    }
  });
  const location = useLocation();
  useEffect(() => {
    if (!location?.state?.reqId) {
      setCheckboxState({});
      setSelectedDrugAllergiesBox([]);
    }
  }, [props?.apiCallCondition]);

  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName}`
            : "col-lg-6 col-md-6 col-sm-12 mb-3"
        }
      >
        <h6 className="fw-600 mb-5">{props?.displayFieldName}</h6>
        <div className={props?.sectionName === "Drug Allergies" ? "row" : ""}>
          {props?.RadioOptions.map(
            (options: any) =>
              options?.isVisable && (
                <div
                  className={
                    props?.sectionName === "Drug Allergies"
                      ? "col-lg-4 col-md-6 col-6 py-1"
                      : ""
                  }
                >
                  <div className="form__group form__group--checkbox mb-3">
                    <label
                      className={
                        props?.labelClassName
                          ? `${props?.labelClassName} fw-400 text-break`
                          : "form-check form-check-inline form-check-solid m-0 fw-400 text-break"
                      }
                    >
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        id={options.id}
                        name={options.name}
                        value={options.value}
                        onChange={(e: any) => {
                          setEvent(e);
                          setCheckboxState((prevState) => ({
                            ...prevState,
                            [options.id]: e.target.checked, // Update the checkbox state
                          }));
                          const checked = e.target.checked;
                          const id = e.target.id;
                          props.onChange(e, options.value, options.label);
                          let obj = {
                            id: e.target.id,
                            label: options.label,
                          };
                          if (checked) {
                            setSelectedDrugAllergiesBox([
                              ...selectedDrugAllergies,
                              obj,
                            ]);
                          }
                          if (!checked) {
                            setSelectedDrugAllergiesBox((preVal: any) =>
                              preVal.filter(
                                (selectedDrugAllergiesInfo: any) =>
                                  selectedDrugAllergiesInfo.id != id
                              )
                            );
                          }
                        }}
                        defaultChecked={options?.isSelectedDefault}
                        checked={
                          checkboxState[options.id]
                          // props.sysytemFieldName === "DrugAllergies"
                          //   ? checkboxState[options.id]
                          //   : props.checked
                        }
                      />

                      {props?.loading ? (
                        <MuiSkeleton height={22} />
                      ) : (
                        <span
                          className={
                            props?.spanClassName ? props?.spanClassName : ""
                          }
                        >
                          {options.label}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              )
          )}
        </div>
        {props?.sectionName === "Drug Allergies" && isVisible && (
          <>
            <div className="d-flex align-items-start gap-2 mt-2">
              <div className="w-100 w-md-300px position-relative">
                <label className="mb-1 fw-500">Others</label>
                <input
                  type="textarea"
                  name="DrugOthres"
                  placeholder="Allergies / Drug Allergies"
                  className="form-control bg-transparent"
                  onChange={(event: { target: { value: any } }) => {
                    handleChange(event);
                  }}
                  onKeyDown={handleKeyDown}
                  ref={inputSearchRef}
                  color="secondary"
                />
                {suggestions.length > 0 && (
                  <div className="positon-absoute shadow-sm mt-2 rounded-2">
                    {isArray(suggestions) &&
                      suggestions.map((drugs: any) => (
                        <>
                          <div
                            className="py-1 bg-hover-light-primary px-3"
                            onClick={() => {
                              if (
                                !selectedDrugAllergies.some(
                                  (drug: { value: any }) =>
                                    drug.value === drugs.value
                                )
                              ) {
                                handleClick(drugs);
                                setSelectedDrugAllergiesBox([
                                  ...selectedDrugAllergies,
                                  drugs,
                                ]);
                                const modifiedEvent = {
                                  target: {
                                    checked: true,
                                    id: drugs.value,
                                  },
                                };
                                props.onChange(
                                  modifiedEvent,
                                  drugs.value,
                                  drugs.label
                                );
                              } else {
                                setTouched(false);
                              }
                            }}
                          >
                            <span id={drugs.label}>{drugs.label}</span>
                          </div>
                        </>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap gap-2 mt-4">
              {selectedDrugAllergies.map((selectedDrugAllergiesInfo: any) => (
                <div className="d-flex badge badge-secondary px-2 fw-500 gap-2 align-items-center pt-2">
                  <i
                    className="bi bi-x-lg fs-7"
                    onClick={() =>
                      removeDrugAllergy(
                        selectedDrugAllergiesInfo?.id,
                        selectedDrugAllergiesInfo.label
                      )
                    }
                  ></i>
                  <span id={selectedDrugAllergiesInfo?.id}>
                    {selectedDrugAllergiesInfo.label}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {props.error && (
        <div className="form__error">
          <span>{props.error}</span>
        </div>
      )}
    </>
  );
};

export default CheckBoxList;

function BySaleRep({
  handleRepClick,
  filteredAllReps,
  removeSelectedRep,
  allRepsSearchTerm,
  filteredSelectedReps,
  setAllRepsSearchTerm,
  selectedRepsSearchTerm,
  setSelectedSalesRepsSearchTerm,
}: any) {
  return (
    <>
      <div className="card shadow-sm rounded border border-warning mt-5">
        <div className="card-header px-4 d-flex justify-content-between align-items-center rounded bg-light-warning min-h-30px">
          <h6 className="text-warning mb-0">Sale Rep</h6>
        </div>
        <div className="card-body py-md-4 py-3 px-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {/* <span className="fw-bold">All Reps</span> */}
                <input
                  className="form-control bg-white mb-3 mb-lg-0 rounded-2 fs-8 h-30px"
                  value={allRepsSearchTerm}
                  onChange={(e) => setAllRepsSearchTerm(e.target.value)}
                  placeholder="Search..."
                  type="text"
                />
                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    <span className="fw-bold required">Sales Rep</span>
                  </div>
                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredAllReps.map((rep: any, index: any) => (
                      <li
                        key={index}
                        onClick={() => handleRepClick(rep)}
                        className="list-group-item py-1 px-2 border-0 cursor-pointer"
                      >
                        <div className="d-flex">
                          <span>{rep.label}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                {/* <span className="fw-bold">Selected Reps</span> */}
                <input
                  className="form-control bg-white mb-3 mb-lg-0 rounded-2 fs-8 h-30px"
                  value={selectedRepsSearchTerm}
                  onChange={(e) =>
                    setSelectedSalesRepsSearchTerm(e.target.value)
                  }
                  placeholder="Search..."
                  type="text"
                />
                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    <span className="fw-bold">Sale Rep List</span>
                  </div>
                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredSelectedReps.map((rep: any, index: any) => (
                      <li
                        onClick={() => removeSelectedRep(rep)}
                        key={index}
                        className="list-group-item py-1 px-2 border-0 cursor-pointer"
                      >
                        {rep.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BySaleRep;

import React from "react";
import { showFieldValue } from "../../../../Utils/Requisition/OrderView";

const RequisitionFields = (props: any) => {
  const { fieldsInfo } = props;
  return (
    <>{showFieldValue(fieldsInfo)}</>

    // <div>
    //   <div className={fieldsInfo.displayType}>
    //     <span>{fieldsInfo?.systemFieldName ?? ""}</span>
    //     <span>{fieldsInfo?.fieldValue ?? ""}</span>
    //   </div>
    // </div>
  );
};

export default RequisitionFields;

import { useRef, useEffect, useState } from "react";
import Select from "react-select";
import { styles } from "../../Utils/Common";
const FacilityDropdown = (props: any) => {
  const inputElementReactSelect = useRef(props?.name);
  const [selectedFacility, setSelectedFacility] = useState(null);
  useEffect(() => {
    if (inputElementReactSelect.current) {
      if (props.errorFocussedInput === inputElementReactSelect.current.id) {
        inputElementReactSelect.current.focus();
      }
    }
  }, [props.errorFocussedInput]);
  useEffect(() => {
    if (props.facilityList?.length === 1) {
      setSelectedFacility(props.facilityList[0]);
      props.onFacilitySelect(props.facilityList[0]);
    }
  }, [props.facilityList.length === 1]);
  useEffect(() => {
    setSelectedFacility(props.selectedFacilities);
  }, [props.selectedFacilities]);
  const handleFacilitySelect = (selectedOption: any) => {
    setSelectedFacility(selectedOption);
    props.onFacilitySelect(selectedOption);
  };
  return (
    <div id={props.name} tabIndex={-1}>
      <Select
        ref={inputElementReactSelect}
        options={props.facilityList}
        theme={(theme) => styles(theme)}
        value={selectedFacility}
        onChange={handleFacilitySelect}
        isSearchable={true}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "var(--kt-input-border-color)",
            color: "var(--kt-input-border-color)",
          }),
        }}
      />
    </div>
  );
};

export default FacilityDropdown;

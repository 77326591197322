// PermissionComponent.tsx
import React from "react";
import { useAbility } from "./AbilityContext";

interface PermissionComponentProps {
  pageName: string;
  permissionIdentifier: string;
  children: any;
}
const PermissionComponent: React.FC<PermissionComponentProps> = ({
  pageName,
  permissionIdentifier,
  children,
}) => {

  const ability = useAbility();
  const isAllowed = ability.can(permissionIdentifier, pageName);
  return isAllowed ? <>{children}</> : null;
};

export default PermissionComponent;

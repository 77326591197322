import { useEffect, useRef, useState } from "react";
import MuiSkeleton from "../MuiSkeleton";
import { assignFormValues } from "../../../Utils/Auth";
import UseMedicationAutoComplete from "../../hooks/Requisition/UseMedicationAutoComplete";

const ActiveMedicationListCheckbox = (props: any) => {
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  let medicationList: any;
  let disableCondition: any;
  props.Inputs[props?.index].fields.map((item: any) => {
    if (item.systemFieldName === "AssignedMedications") {
      medicationList = item?.medicationList;
    }
    if (item.systemFieldName === "NoMedication") {
      disableCondition = item?.defaultValue;
    }
  });
  const [selectedMedications, setSelectedMedications] = useState<any>(
    props.options ? props.options : []
  );
  useEffect(() => {
    if (props.sysytemFieldName === "OtherMedication") {
      assignFormValues(
        props.Inputs,
        props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        props.options,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
    }
  }, []);

  useEffect(() => {
    if (props.sysytemFieldName === "OtherMedication") {
      const updatedList = selectedMedications.map((item: any) => {
        return {
          medicationName: item.medicationName,
          medicationCode: item.medicationCode,
        };
      });
      let newInput = assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        updatedList,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInput?.then((res) => {
        if (!props?.infectiousData) {
          console.error("props.infectiousData is undefined or null");
          return;
        }

        try {
          let infectiousDataCopy = JSON?.parse(
            JSON?.stringify(props?.infectiousData)
          );

          if (infectiousDataCopy) {
            const index = FindIndex(props?.infectiousData, props?.ArrayReqId);
            if (index === -1) {
              console.error(
                "FindIndex returned -1, check your ArrayReqId or data structure"
              );
              return;
            }
            infectiousDataCopy[index].sections = res;
            if (props?.setInfectiousData) {
              props.setInfectiousData([...infectiousDataCopy]);
            }
          }
        } catch (error) {
          console.error("Error parsing infectiousData:", error);
        }
      });
    }
  }, [
    selectedMedications,
    props.sysytemFieldName,
    props.Inputs,
    props.dependenceyControls,
    props.index,
    props.depControlIndex,
    props.fieldIndex,
    props.isDependency,
    props.repeatFieldSection,
    props.isDependencyRepeatFields,
    props.repeatFieldIndex,
    props.repeatDependencySectionIndex,
    props.repeatDepFieldIndex,
    props.setInputs,
    props.infectiousData,
    props.setInfectiousData,
    props.ArrayReqId,
  ]);

  const [AssignedMedicationList, setAssignedMedicationList] = useState<any>(
    props.sysytemFieldName === "AssignedMedications"
      ? props.options
          .filter((option: any) => option.isSelected)
          .map((option: any) => ({
            medicationCode: option.medicationCode,
            medicationName: option.medicationName,
            isSelected: option.isSelected,
          }))
      : []
  );

  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { suggestions, handleChange, handleKeyDown, handleClick } =
    UseMedicationAutoComplete(inputSearchRef);

  const updateFields = (
    code: string,
    isSelected: boolean,
    selectedMedications: any
  ) => {
    if (props.Inputs[props?.index]?.fields) {
      props.Inputs[props.index].fields.forEach((field: any) => {
        if (field.systemFieldName === "AssignedMedications") {
          const medication = field.medicationList.find(
            (med: any) => med.medicationCode === code
          );
          if (medication) {
            medication.isSelected = isSelected;
          }
        }
      });
    }
    let newInputs = assignFormValues(
      props?.Inputs,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      selectedMedications,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      if (props?.setInfectiousData) {
        props.setInfectiousData([...infectiousDataCopy]);
      }
    });
  };

  const handleChangeCheckbox = (event: any, code: string, name: string) => {
    let obj = {
      medicationCode: code,
      medicationName: name,
    };
    if (event.target.checked) {
      setAssignedMedicationList((prevValue: any) => {
        const updatedList = [...prevValue, obj];
        updateFields(code, true, updatedList);
        return updatedList;
      });
    } else {
      setAssignedMedicationList((prevMedications: any) => {
        const updatedList = prevMedications.filter(
          (item: any) => item.medicationCode !== code
        );
        updateFields(code, false, updatedList);
        return updatedList;
      });
    }
  };
  const handleOtherMedication = async (item: any) => {
    const obj = {
      medicationName: item?.medicationName,
      medicationCode: item?.medicationCode,
    };
    setSelectedMedications((prevValue: any) => {
      const updatedList = [...prevValue, obj];
      return updatedList;
    });
    const updatedList = [...selectedMedications, obj];
    try {
      const newInputs = await assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        updatedList,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );

      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );

      if (infectiousDataCopy) {
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = newInputs;

        if (props?.setInfectiousData) {
          props.setInfectiousData([...infectiousDataCopy]);
        }
      }
    } catch (error) {
      console.error("Error updating fields:", error);
    }
  };

  const removeMedication = async (code: number) => {
    setSelectedMedications((prevMedications: any) =>
      prevMedications.filter((item: any) => item.medicationCode !== code)
    );
    let newInputs = await assignFormValues(
      props?.Inputs,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      selectedMedications,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    let infectiousDataCopy = JSON.parse(JSON.stringify(props?.infectiousData));
    infectiousDataCopy[
      FindIndex(props?.infectiousData, props.ArrayReqId)
    ].sections = newInputs;
    props?.setInfectiousData &&
      props?.setInfectiousData([...infectiousDataCopy]);
  };

  return (
    <>
      <div className={"col-lg-12 col-md-12 col-sm-12 mb-3"}>
        <h6 className="mb-3">{props?.displayFieldName}</h6>
        {props.sysytemFieldName === "AssignedMedications" && (
          <div className="row">
            {props.options?.map((options: any) => (
              <div className="col-lg-4 col-md-6 col-6 py-1">
                <div className="form__group form__group--checkbox mb-3">
                  <label
                    className={
                      props?.labelClassName
                        ? `${props?.labelClassName} fw-400 text-break`
                        : "form-check form-check-inline form-check-solid m-0 fw-400 text-break"
                    }
                  >
                    <input
                      className="form-check-input h-20px w-20px"
                      type="checkbox"
                      id={options.medicationCode}
                      name={options.medicationName}
                      value={options.medicationCode}
                      onChange={(e: any) => {
                        handleChangeCheckbox(
                          e,
                          options.medicationCode,
                          options.medicationName
                        );
                      }}
                      defaultChecked={options?.isSelected}
                      checked={options.isSelected}
                      disabled={disableCondition}
                    />
                    {props?.loading ? (
                      <MuiSkeleton height={22} />
                    ) : (
                      <span
                        className={
                          props?.spanClassName ? props?.spanClassName : ""
                        }
                      >
                        {options.medicationName}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
        <>
          {props.sysytemFieldName === "OtherMedication" && (
            <div className="d-flex align-items-start gap-2 mt-2">
              <div className="w-100 w-md-300px position-relative">
                <input
                  type="textarea"
                  name="Medications"
                  placeholder="Medications"
                  className="form-control bg-transparent"
                  onChange={(event: { target: { value: any } }) => {
                    handleChange(event);
                  }}
                  onKeyDown={handleKeyDown}
                  ref={inputSearchRef}
                  color="secondary"
                  disabled={disableCondition}
                />

                {suggestions?.length > 0 && (
                  <div
                    className="shadow-sm mt-2 rounded-2"
                    style={{
                      maxHeight: "300px", // Adjust the height as needed
                      overflowY: "auto",
                    }}
                  >
                    {suggestions?.map((item: any) => (
                      <div
                        key={item.medicationCode}
                        className="py-1 bg-hover-light-primary px-3"
                        onClick={() => {
                          handleClick(item);
                          handleOtherMedication(item);
                        }}
                      >
                        <span id={item?.medicationCode}>
                          {item?.medicationName}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {props.sysytemFieldName === "OtherMedication" && (
            <div className="d-flex flex-wrap gap-2 mt-4">
              {selectedMedications.map((info: any) => (
                <div className="d-flex badge badge-secondary px-2 fw-500 gap-2 align-items-center pt-2">
                  <i
                    className="bi bi-x-lg fs-7"
                    onClick={() => removeMedication(info?.medicationCode)}
                  ></i>
                  <span id={info?.medicationCode}>{info.medicationName}</span>
                </div>
              ))}
            </div>
          )}
        </>
      </div>
      {props.error && (
        <div className="form__error">
          <span>{props.error}</span>
        </div>
      )}
    </>
  );
};
export default ActiveMedicationListCheckbox;

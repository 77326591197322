import React from "react";

function ByFacility({
  handleFacilityClick,
  filteredAllFacilities,
  allFacilitiesSearchTerm,
  removeSelectedFacilities,
  filteredSelectedFacilities,
  setAllFacilitiesSearchTerm,
  selectedFacilitiesSearchTerm,
  setSelectedFacilitiesSearchTerm,
}: any) {
  return (
    <>
      <div className="card shadow-sm rounded border border-primary mt-5">
        <div className="card-header px-4 d-flex justify-content-between align-items-center rounded bg-light-primary min-h-30px">
          <h6 className="text-primary mb-0">Facilities</h6>
        </div>
        <div className="card-body py-md-4 py-3 px-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {/* <span className="fw-bold">All Facilities</span> */}
                <input
                  className="form-control bg-white mb-3 mb-lg-0 rounded-2 fs-8 h-30px"
                  value={allFacilitiesSearchTerm}
                  onChange={(e) => setAllFacilitiesSearchTerm(e.target.value)}
                  placeholder="Search..."
                  type="text"
                />
                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    <span className="fw-bold required">Test List</span>
                  </div>
                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredAllFacilities.map((facility: any, index: any) => (
                      <li
                        key={index}
                        onClick={() => handleFacilityClick(facility)}
                        className="list-group-item py-1 px-2 border-0 cursor-pointer"
                      >
                        <div className="d-flex">
                          <span>{facility.label}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                {/* <span className="fw-bold">Selected Facilities</span> */}
                <input
                  className="form-control bg-white mb-3 mb-lg-0 rounded-2 fs-8 h-30px"
                  value={selectedFacilitiesSearchTerm}
                  onChange={(e) =>
                    setSelectedFacilitiesSearchTerm(e.target.value)
                  }
                  placeholder="Search..."
                  type="text"
                />
                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    <span className="fw-bold">Selected Test List</span>
                  </div>
                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredSelectedFacilities.map(
                      (facility: any, index: any) => (
                        <li
                          key={index}
                          onClick={() => removeSelectedFacilities(facility)}
                          className="list-group-item py-1 px-2 border-0 cursor-pointer"
                        >
                          {facility.label}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ByFacility;

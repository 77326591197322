import moment from "moment";
import { Route, useLocation } from "react-router-dom";
import PageNotFound from "../../Shared/Common/Pages/PageNotFound";
import { PortalTypeEnum } from "../Common/Enums/Enums";
import { do_it, isJson } from "../Common/Requisition";
var CryptoJS = require("crypto-js");

export const getToken = () => {
  //const userInfo: any = localStorage.getItem("userinfo") || "";

  let UserInfo = "";
  let persistData: any = localStorage.getItem("persist:root");
  let checkIsJson = isJson(persistData);
  let reduxParsedData: any = "";
  let reduxReducerParsedData: any = "";
  if (checkIsJson) {
    reduxParsedData = JSON.parse(persistData);
    let checkIsJsonReducer = isJson(reduxParsedData?.Reducer);
    if (checkIsJsonReducer) {
      reduxReducerParsedData = JSON.parse(reduxParsedData?.Reducer);
    }
  }
  if (
    reduxReducerParsedData?.userInfo?.constructor === Object &&
    Object.keys(reduxReducerParsedData?.userInfo)?.length === 0
  ) {
    reduxReducerParsedData.userInfo = "";
  }

  //
  const userInfo: any = sessionStorage.getItem("userinfo") || "";
  const userInfoLocalStorage = localStorage.getItem("userinfo") || "";
  let parsedUserInfo;
  UserInfo = userInfo
    ? userInfo
    : userInfoLocalStorage
    ? userInfoLocalStorage
    : reduxReducerParsedData?.userInfo;
  if (UserInfo) {
    if (process.env.NODE_ENV === "development") {
      parsedUserInfo = JSON.parse(Decrypt(UserInfo));
    }
    if (process.env.NODE_ENV === "production") {
      let decryptedUserInfo = Decrypt(UserInfo);
      let checkIsJson = isJson(decryptedUserInfo);
      if (checkIsJson) {
        parsedUserInfo = JSON.parse(decryptedUserInfo);
      } else {
        parsedUserInfo = decryptedUserInfo;
      }
    }
    const userInfoRedux: any = sessionStorage.getItem("userinfo") || "";
    if (!userInfoRedux) {
      sessionStorage.setItem("userinfo", reduxReducerParsedData?.userInfo);
    }
    return parsedUserInfo.token;
  }
  if (!UserInfo) {
    return undefined;
  }
};

////
export const getTokenData = () => {
  const userInfo: any = sessionStorage.getItem("userinfo") || "";
  let parsedUserInfo;

  if (userInfo) {
    parsedUserInfo = JSON.parse(Decrypt(userInfo));
    return parsedUserInfo;
  }
  if (!userInfo) {
    return undefined;
  }
};
////
export const GetSiteKey = () => {
  const { protocol, hostname } = window.location;
  if (hostname.split(".").length > 1) return hostname.split(".")[1];
  else return hostname.split(".")[0];
  // return `${protocol}//${subdomain}.example.com`;
};

export const getMachineKey = (): string | undefined => {
  if (navigator && navigator.userAgent) {
    const userAgent = navigator.userAgent;
    let machineKey = btoa(userAgent);
    machineKey = machineKey.substring(0, 50);
    return machineKey;
  }
  return undefined;
};

const generateRandomKey = () => {
  return (
    navigator.appCodeName +
    navigator.appName +
    navigator.language +
    navigator.platform
  );
};

export const Encrypt = (word: string) => {
  var ciphertext = CryptoJS.AES.encrypt(word, generateRandomKey()).toString();
  return ciphertext;
};

export function Decrypt(word: string | null) {
  var bytes = CryptoJS.AES.decrypt(word, generateRandomKey());
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export const getParameterByName = (name: any, url: any): string | null => {
  const params = new URLSearchParams(url.split("?")[1]);
  return params.get(name);
};

//get machine key in typescrip

export function getRoutes(routesArr: any) {
  const routes = [];
  for (let i = 0; i < routesArr.length; i++) {
    const Element = routesArr[i].element;
    routes.push(
      <Route path={routesArr[i].path} element={<Element />} key={i} />
    );
  }

  return routes;
}
export function compareRoutes(routesArr: any, menus: any) {
  const extractPaths = (claims: any[]): string[] => {
    return claims?.flatMap((claim: any) => {
      const paths: string[] = [];
      if (claim?.linkUrl) {
        let updatedUrl = claim.linkUrl.replace(/^\//, "");
        paths.push(updatedUrl);
      }
      if (claim?.subClaims) {
        paths.push(...extractPaths(claim?.subClaims));
      }
      return paths;
    });
  };
  // Extract paths from menus
  const menuPaths = menus?.flatMap((menu: any) =>
    extractPaths(menu?.claims || [])
  );
  // Find common paths
  const commonPaths = routesArr?.filter((route: any) =>
    menuPaths?.includes(route?.path)
  );
  return commonPaths;
}
export function getAuthRoutes(routesArr: any, menus: any) {
  let extractedRoutes = compareRoutes(routesArr, menus);
  const routes = [];
  for (let i = 0; i < extractedRoutes?.length; i++) {
    const Element = extractedRoutes[i]?.element;
    routes.push(
      <Route path={extractedRoutes[i]?.path} element={<Element />} key={i} />
    );
  }
  routes.push(<Route path="*" element={<PageNotFound />} key="not-found" />);
  return routes;
}

export const setLabCredentials = (pathname: string, loginData: any) => {
  let filteredDataBasedonUrl = loginData?.authTenants?.filter(
    (item: any) => item.url === pathname
  );
  if (filteredDataBasedonUrl.length > 0) {
    return filteredDataBasedonUrl[0];
  }
};

export const checkIsSelected = (authTenants: any) => {
  let selectedTenants = authTenants.filter((items: any) => items?.isSelected);
  return selectedTenants;
};

export const setHardCodedDefaultLab = (data: any) => {
  data.authTenants.forEach((items: any, index: any, arrayItself: any) => {
    arrayItself[0].isSelected = true;
  });
  return data;
};
export const GetSelectedTenant = (authTenants: any) => {
  let selectedTenants = authTenants.find((items: any) => items?.isSelected);
  return selectedTenants;
};
export const GetAdminTypeFromTenant = (tenant: any) => {
  return tenant.infomationOfLoggedUser.adminType;
};
export const GetDefaultTenant = (authTenants: any) => {
  let DefaultTenants = authTenants.find((items: any) => items?.isDefault);
  return DefaultTenants;
};
export const GetLoggedUserInfo = (authTenants: any) => {
  let selectedTenants = authTenants.find((items: any) => items?.isSelected);
  return selectedTenants;
};

export const getLoggedInLabDetails = (loginData: any) => {
  let selectedTenantInfo = loginData?.authTenants?.find(
    (tenantsInfo: any) => tenantsInfo.isSelected
  );

  let userType: number = loginData?.userType;
  let adminType = selectedTenantInfo
    ? selectedTenantInfo?.infomationOfLoggedUser?.adminType
    : null;
  let isFacilityUser = selectedTenantInfo
    ? selectedTenantInfo?.infomationOfLoggedUser?.adminType ===
      PortalTypeEnum.Facility
    : null;
  let selectedLabFacilities;
  if (selectedTenantInfo) {
    selectedLabFacilities =
      selectedTenantInfo?.infomationOfLoggedUser?.facilities;
  }

  return {
    authTenants: loginData.authTenants,
    tenantCount: loginData.authTenants.length,
    userType: userType,
    adminType: adminType,
    isFacilityUser: isFacilityUser,
    selectedTenantsInfo: selectedTenantInfo,
    SelectedLabFacilities: selectedLabFacilities,
  };
};
export function fnBrowserDetect() {
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset = nAgt.indexOf("OPR")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 4);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset + 4);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  return {
    nAgt,
    browserName,
    fullVersion,
    majorVersion,
    nameOffset,
    verOffset,
    ix,
  };
}
export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}
export const assignFormValues = async (
  Inputs: any,
  dependenceyControls: any,
  index: any,
  depControlIndex: any,
  fieldIndex: any,
  inputValue: any,
  isDependency: boolean,
  repeatFieldSection: boolean,
  isDependencyRepeatFields: boolean,
  repeatFieldIndex: any,
  repeatDependencySectionIndex: any,
  repeatDepFieldIndex: any,
  selectedText: any,
  setInputs: any
) => {
  if (repeatFieldSection) {
    if (!isDependencyRepeatFields) {
      // overAllRepeatFields[index].fieldIndex.defaultValue = inputValue;
      Inputs[index].fields[fieldIndex].repeatFields[
        repeatFieldIndex
      ].defaultValue = inputValue;

      // Inputs[index].fields[fieldIndex].repeatFields = updatedRepeatFields;

      const dynamicObj: { [key: string]: any } = {};
      // if (
      //   Inputs[index].fields[fieldIndex].uiType === "ControlDynamicAutoComplete"
      // ) {
      Inputs[index].fields[fieldIndex].repeatFields.forEach((field: any) => {
        if (field.systemFieldName !== "RepeatStart") {
          dynamicObj[field.systemFieldName] = field.defaultValue;
        }
      });
      Inputs[index].fields[fieldIndex].defaultValue =
        JSON.stringify(dynamicObj);
      // }

      Inputs[index].fields[fieldIndex].repeatFields[
        repeatFieldIndex
      ].selectedText = selectedText;
      if (selectedText === "Self Pay" || selectedText === "Client Bill") {
        Inputs[index].fields[fieldIndex].repeatFields.map((i: any) => {
          if (
            i.systemFieldName === "RelationshipToInsured" ||
            i.systemFieldName === "InsuranceProviderID" ||
            i.systemFieldName === "PrimaryPolicyID" ||
            i.systemFieldName === "PrimaryGroupID" ||
            i.systemFieldName === "InsurancePhone" ||
            i.systemFieldName === "SubscriberName" ||
            i.systemFieldName === "SubscriberDOB" ||
            i.systemFieldName === "AccidentDate" ||
            i.systemFieldName === "AccidentType" ||
            i.systemFieldName === "AccidentState"
          ) {
            i.defaultValue = "";
            i.selectedText = "";
          } else {
            Inputs[index].fields[fieldIndex].repeatFields[
              repeatFieldIndex
            ].defaultValue = inputValue;
            Inputs[index].fields[fieldIndex].repeatFields[
              repeatFieldIndex
            ].selectedText = selectedText;
          }
        });
      }
      return Inputs;
    }
    if (isDependencyRepeatFields) {
      // overAllRepeatFields[index].fieldIndex.defaultValue = inputValue;
      Inputs[index].fields[fieldIndex].repeatDependencyControls[
        repeatDependencySectionIndex
      ].dependecyFields[repeatDepFieldIndex].defaultValue = inputValue;
      Inputs[index].fields[fieldIndex].repeatDependencyControls[
        repeatDependencySectionIndex
      ].dependecyFields[repeatDepFieldIndex].selectedText = selectedText;
      return;
    }
  }

  if (!isDependency) {
    Inputs[index].fields[fieldIndex].defaultValue =
      Inputs[index].fields[fieldIndex].systemFieldName === "PatientOption"
        ? btoa(inputValue)
        : inputValue;
    Inputs[index].fields[fieldIndex].selectedText = selectedText;
    ////signature pad values assign
    if (
      Inputs[index].sectionName == "Physician Signature" &&
      Inputs[index].fields[fieldIndex].systemFieldName != "PhysicianSignature"
    ) {
      let signndex = Inputs[index].fields.findIndex(
        (items: any) => items?.systemFieldName === "PhysicianSignature"
      );
      let signText = Inputs[index].fields[signndex]?.signatureText;

      Inputs[index].fields[signndex].signatureText = inputValue;
      Inputs[index].fields[signndex].defaultValue = btoa(inputValue);
      if (inputValue.includes("base64")) {
        let enableRule = Inputs[index].fields[signndex]?.enableRule;
        if (enableRule) {
          Inputs[index].fields[signndex].enableRule = "";
        }
      }
      if (!inputValue.includes("base64")) {
        Inputs[index].fields[fieldIndex].defaultValue = "";
      }
    }
    if (Inputs[index].sectionName == "Patient Signature") {
      let patientIndex = Inputs.findIndex(
        (inputData: any) => inputData?.sectionName == "Patient"
      );
      ///
      let patientFieldIndex = Inputs[patientIndex].fields.findIndex(
        (fieldsData: any) =>
          fieldsData?.systemFieldName == "SocialSecurityNumber"
      );
      let dobIndex = Inputs[patientIndex].fields.findIndex(
        (fieldsData: any) => fieldsData?.systemFieldName == "DOB"
      );
      ///

      if (inputValue === "Date of Birth") {
        Inputs[index].fields[2].uiType = "Date";
        let dob = Inputs[patientIndex].fields[dobIndex].defaultValue;
        Inputs[index].fields[2].defaultValue = dob;
      } else {
        Inputs[index].fields[2].uiType = "TextArea";
        if (inputValue === "Last 4 SSN Digits") {
          Inputs[index].fields[2].length = 4;
          let ssn = Inputs[patientIndex].fields[patientFieldIndex].defaultValue;
          Inputs[index].fields[2].defaultValue = ssn.slice(-4);
        }
        if (inputValue === "Mother's Maiden Name") {
          Inputs[index].fields[2].defaultValue = "";
          Inputs[index].fields[2].length = "";
        }
        if (fieldIndex === 2 && index === 6 && inputValue) {
          let patientDobValue =
            Inputs[patientIndex].fields[dobIndex].defaultValue;
          let patientssnValue =
            Inputs[patientIndex].fields[patientFieldIndex].defaultValue;
          patientssnValue = patientssnValue.substring(
            patientssnValue.length,
            patientssnValue.length - 4
          );
          ///for dob
          if (
            patientDobValue !== inputValue &&
            Inputs[6].fields[1].defaultValue === "Date of Birth"
          ) {
            Inputs[index].fields[fieldIndex].enableRule = `dob didn't match`;
          }
          ///
          ///for ssn
          if (
            patientssnValue != inputValue &&
            Inputs[6].fields[1].defaultValue == "Last 4 SSN Digits"
          ) {
            Inputs[index].fields[fieldIndex].enableRule = `ssn didn't match`;
          }
          if (
            patientssnValue == inputValue ||
            Inputs[6].fields[1].defaultValue == `Mother's Maiden Name`
            // Inputs[6].fields[1].defaultValue == `Date of Birth`
          ) {
            Inputs[index].fields[fieldIndex].enableRule = ``;
          }
          ////
          ///for dob
          if (
            patientssnValue == inputValue ||
            Inputs[6].fields[1].defaultValue == `Mother's Maiden Name`
            //Inputs[6].fields[1].defaultValue == `Last 4 SSN Digits`
          ) {
            Inputs[index].fields[fieldIndex].enableRule = ``;
          }
          ///
        }
      }
      if (inputValue == false) {
        Inputs[index].fields[4].signatureText = "";
        return;
      }

      if (Inputs[index].fields[3].defaultValue) {
        Inputs[index].fields[4].signatureText = selectedText;
        Inputs[index].fields[4].defaultValue = btoa(selectedText);
      } else if (
        Inputs[index].fields[0].defaultValue &&
        Inputs[index].fields[1].defaultValue &&
        Inputs[index].fields[2].defaultValue
      ) {
        if (Inputs[index].fields[2].defaultValue.length === 4) {
          let browserInfo = fnBrowserDetect();
          let osInfo = getOS();
          let inputsCopy = JSON.parse(JSON.stringify(Inputs));
          let signatureText: any = await do_it(
            {
              fullName: Inputs[index].fields[0].defaultValue,
              uniqueKey: moment().unix().toString(),
              ipAddress: "",
              computerInfo: JSON.stringify(osInfo),
              browserInfo: JSON.stringify(browserInfo),
              controlsInfo: "controls",
            },
            (res: any) => {
              // let { fullname, date, time, uniqueKey } = res?.data;
              console.log(res, "res signature text");

              const text = `${res?.fullName}!${res?.uniqueKey}!Date: ${res?.date}    Time ${res?.time} (UTC Time Zone)`;
              inputsCopy[index].fields[4].signatureText = text;
              inputsCopy[index].fields[4].defaultValue = btoa(text);
              setInputs(inputsCopy);
            },
            Inputs,
            index
          );
        }

        // ipAddress.then((res: any) => {
        //   getSignatureText({
        //     fullName: Inputs[index].fields[0].defaultValue,
        //     uniqueKey: moment().unix(),
        //     ipAddress: res,
        //     computerInfo: "string",
        //     browserInfo: "string",
        //     controlsInfo: "string",
        //   });
        // });
        // let value =
        //   Inputs[index].fields[0].defaultValue +
        //   Inputs[index].fields[1].defaultValue +
        //   Inputs[index].fields[2].defaultValue;
        // Inputs[index].fields[4].signatureText = value;

        if (!inputValue?.includes("base64")) {
          Inputs[index].fields[4].defaultValue = "";
        }
      }
      if (
        Inputs[index].fields[0].defaultValue &&
        Inputs[index].fields[1].defaultValue &&
        Inputs[index].fields[2].defaultValue
      ) {
        if (!inputValue) {
          Inputs[index].fields[4].signatureText = "";
        }
      }
    }
  }
  if (isDependency) {
    Inputs[index].dependencyControls[depControlIndex].dependecyFields[
      fieldIndex
    ].defaultValue = inputValue;
    Inputs[index].dependencyControls[depControlIndex].dependecyFields[
      fieldIndex
    ].selectedText = selectedText;
  }
  return Inputs;
  //setInputs(Inputs);
};
///////
export const assignFormValuesForMedicalNecessity = (
  Inputs: any,
  dependenceyControls: any,
  index: any,
  depControlIndex: any,
  fieldIndex: any,
  inputValue: any,
  isDependency: boolean,
  repeatFieldSection: boolean,
  isDependencyRepeatFields: boolean,
  repeatFieldIndex: any,
  repeatDependencySectionIndex: any,
  repeatDepFieldIndex: any,
  selectedText: any,
  setInfectiousData: any
) => {
  let inputsCopy = [...Inputs];
  if (inputsCopy[index].sectionName !== "Medical Necessity") return;

  if (!isDependency) {
    inputsCopy[index].fields[fieldIndex].defaultValue = inputValue;
    inputsCopy[index].fields[fieldIndex].selectedText = selectedText;

    ////this is for signature medical neccesity values assign
    // if (inputsCopy[index].sectionName === "Medical Necessity") {
    //   if (
    //     inputsCopy[index].fields[fieldIndex].systemFieldName ===
    //       "ExposuretoCovid19" ||
    //     inputsCopy[index].fields[fieldIndex].systemFieldName ===
    //       "SignandSymptom" ||
    //     inputsCopy[index].fields[fieldIndex].systemFieldName ===
    //       "RecommendedTest"
    //   ) {
    //     if (inputsCopy[index].fields[fieldIndex].defaultValue == true) {
    //       inputsCopy[index].fields[fieldIndex + 1].disabled = true;
    //     }
    //     if (inputsCopy[index].fields[fieldIndex].defaultValue == false) {
    //       inputsCopy[index].fields[fieldIndex + 1].disabled = false;
    //     }
    //   }
    // }
    return inputsCopy;
    //setInfectiousData(inputsCopy);
    ////this is for signature medical neccesity values assign
  }
};
//////

// formDataApi2 = formValuesForApi(
//   item.sections,
//   props?.Requisition.ReqReducer.selectedReqObjData,
//   location?.state?.reqId,
//   "",
//   item?.reqId,
//   item?.requistionName
// );
// formValuesForApi(
//   Inputs,
//   requisitionObj,
//   location?.state?.reqId,
//   ""
// )

// item.sections,
// props?.Requisition.ReqReducer.selectedReqObjData,
// location?.state?.reqId,
// "",
// item?.reqId,
// item?.requistionName

export const formValuesForApi = (
  Inputs: any,
  SelecetedReqInfo: any,
  reqId: any,
  action: string,
  RequisitionId?: any,
  RequisitionName?: any
) => {
  let requisitionObjToSend: any = {
    requisitionId: reqId ? reqId : 0,
    isPatientInfoChanged: false,
    action: action ?? "",
    requisitions: [],
    missingFields: [],
  };
  let obj: any = {
    reqId: RequisitionId ? RequisitionId : 0,
    reqName: RequisitionName ? RequisitionName : "common",
    reqSections: [],
  };
  Inputs.forEach((SectionData: any) => {
    let parentObj: any = {
      sectionId: SectionData?.sectionId,
      sectionName: SectionData?.sectionName,
      fields: [],
    };
    var sectionsFieldsObj = {
      systemFields: [],
      customFields: [],
    };
    SectionData.fields.forEach((fieldsData: any) => {
      let fieldArray: any = GetFieldData(fieldsData, SectionData);
      //if (Object.keys(fieldInfoObj).length === 0) return;
      fieldArray?.forEach((element: any) => {
        parentObj.fields.push(element);
      });

      // if (fieldsData?.sectionType == 1) {
      //   sectionsFieldsObj.systemFields =
      //     sectionsFieldsObj.systemFields?.concat(arraylst);
      // }
      // if (fieldsData?.sectionType == 2) {
      //   sectionsFieldsObj.customFields =
      //     sectionsFieldsObj.customFields?.concat(arraylst);
      // }
    });
    if (parentObj.fields.length > 0) {
      obj.reqSections = [...obj.reqSections, parentObj];
    }
    // var mysection = obj.reqSections.findIndex(
    //   (x: any) => x.sectionId == parentObj.sectionId
    // );
    // if (mysection > -1) {
    //   obj.reqSections[mysection].fields.push(sectionsFieldsObj);
    // } else {
    //   parentObj.fields.push(sectionsFieldsObj);

    //   if (
    //     sectionsFieldsObj?.systemFields.length > 0 ||
    //     sectionsFieldsObj.customFields.length > 0
    //   ) {
    //     obj.reqSections = [...obj.reqSections, parentObj];
    //   }
    // }
  });
  let billingInformationFields = Inputs.filter(
    (inputsData: any) => inputsData?.sectionName === "Billing Information"
  );

  formValuesForApiBillingInformation(billingInformationFields, obj);
  requisitionObjToSend.requisitions = [obj];

  console.log(requisitionObjToSend, "requisitionObjToSend");
  return requisitionObjToSend;
};
const GetFieldData = (
  fieldsData: any,
  SectiontData: any,
  visited = new Set()
) => {
  let lst: any[] = [];
  if (!fieldsData?.defaultValue) return lst;

  const fieldKey = fieldsData?.systemFieldName;
  if (visited.has(fieldKey)) return lst;

  visited.add(fieldKey);

  let fieldLevelObj = {
    controlId: fieldsData?.controlId,
    displayName: fieldsData?.displayFieldName,
    uiType: fieldsData?.uiType,
    systemFieldName: fieldsData?.systemFieldName,
    fieldValue: fieldsData?.defaultValue,
    fieldType: fieldsData?.sectionType,
    selectedText: fieldsData?.selectedText,
    previewDisplayType: fieldsData?.previewDisplayType,
    previewSortOrder: fieldsData?.previewSortOrder,
  };

  if (
    SectiontData?.sectionName === "Patient Signature" ||
    SectiontData?.sectionName === "Physician Signature"
  ) {
    fieldLevelObj.fieldValue = fieldLevelObj.fieldValue;
  }

  lst.push(fieldLevelObj);

  const options = SectiontData?.dependencyControls?.filter(
    (x: any) => x.value === fieldsData?.defaultValue
  );

  if (options.length === 0) return lst;

  options.forEach((dependecyData: any) => {
    dependecyData?.dependecyFields.forEach((fieldsDataDependecies: any) => {
      const arraylst = GetFieldData(
        fieldsDataDependecies,
        SectiontData,
        visited
      );
      lst = lst.concat(arraylst);
    });
  });

  return lst;
};

/////////for infectious disease description slice
const getFieldDataWithDescription = (fieldsData: any, SectiontData: any) => {
  if (!fieldsData?.description) return;
  let fieldLevelObj = {
    controlId: fieldsData?.controlId,
    controlName: "Description",
    controlValue: fieldsData?.description,
  };
  return fieldLevelObj;
};
//////////////////////
///////////////////////for billing information
const GetBillingInformationFieldData = (fieldsData: any, SectiontData: any) => {
  let lst: any[] = [];
  //let fieldLevelObj = {};
  //if (!fieldsData?.defaultValue) return fieldLevelObj;
  if (!fieldsData?.defaultValue) return lst;
  let fieldLevelObj = {
    controlId: fieldsData?.controlId,
    displayName: fieldsData?.displayFieldName,
    uiType: fieldsData?.uiType,
    systemFieldName: fieldsData?.systemFieldName,
    fieldValue: fieldsData?.defaultValue,
    fieldType: fieldsData?.sectionType,
    selectedText: fieldsData?.selectedText,
    previewDisplayType: fieldsData?.previewDisplayType,
    previewSortOrder: fieldsData?.previewSortOrder,
  };
  lst.push(fieldLevelObj);
  //
  var option = SectiontData?.repeatDependencyControls?.filter(
    (x: any) => x.value === fieldsData?.defaultValue
  );
  if (option.length == 0) return lst;
  option.forEach((dependecyData: any) => {
    dependecyData?.dependecyFields.forEach((fieldsDataDependecies: any) => {
      let arraylst = GetBillingInformationFieldData(
        fieldsDataDependecies,
        SectiontData
      );
      lst = lst.concat(arraylst);
    });
  });
  return lst;
};
const formValuesForApiBillingInformation = (
  billingInformationFields: any,
  obj: any
) => {
  billingInformationFields.forEach((SectionData: any) => {
    SectionData.fields.forEach((fieldsLevelData: any) => {
      if (fieldsLevelData?.displayFieldName !== "Repeat Start") return;
      let parentObj: any = {
        sectionId: SectionData?.sectionId,
        sectionName: SectionData?.sectionName,
        fields: [],
      };

      fieldsLevelData.repeatFields.forEach((fieldsData: any) => {
        let fieldArray: any = GetBillingInformationFieldData(
          fieldsData,
          fieldsLevelData
        );

        fieldArray?.forEach((element: any) => {
          parentObj.fields.push(element);
        });
      });
      if (parentObj.fields.length > 0) {
        obj.reqSections = [...obj.reqSections, parentObj];
      }
    });
  });
  return obj;
};

const getRepeatedFieldValues = (inputs: any, obj: any) => {
  inputs.forEach((SectionData: any) => {
    SectionData.fields.forEach((fieldsLevelData: any) => {
      let parentObj: any = {
        sectionId: SectionData?.sectionId,
        sectionName: SectionData?.sectionName,
        fields: [],
      };

      console.log(fieldsLevelData, "fieldsLevelData.repeatFields");
      if (fieldsLevelData.systemFieldName) {
        return [...parentObj.fields, fieldsLevelData];
      }

      // fieldsLevelData.repeatFields.forEach((fieldsData: any) => {
      //   console.log(fieldsData, "fieldsData");
      //   let fieldArray: any = GetBillingInformationFieldData(
      //     fieldsData,
      //     fieldsLevelData
      //   );
      //   fieldArray.length &&
      //     fieldArray?.forEach((element: any) => {
      //       parentObj.fields.push(element);
      //     });
      // });
      if (parentObj.fields.length > 0) {
        obj.commonSections = [...obj.commonSections, parentObj];
      }
    });
  });
  return obj;
};

// specific For Patient
export const formValuesForPatient = (Inputs: any) => {
  let obj: any = {
    id: 0,
    commonSections: [],
  };

  Inputs.forEach((SectionData: any) => {
    let parentObj: any = {
      sectionId: SectionData?.sectionId,
      sectionName: SectionData?.sectionName,
      fields: [],
    };

    SectionData.fields.forEach((fieldsData: any) => {
      let fieldArray: any = GetFieldData(fieldsData, SectionData);
      fieldArray?.forEach((element: any) => {
        parentObj.fields.push(element);
      });
    });

    SectionData.dependencyControls.forEach((fieldsData: any) => {
      fieldsData.dependecyFields.map((fieldData: any) => {
        let fieldArray: any = GetFieldData(fieldData, SectionData);
        fieldArray?.forEach((element: any) => {
          parentObj.fields.push(element);
        });
      });
    });

    if (parentObj.fields.length > 0) {
      obj.commonSections = [...obj.commonSections, parentObj];
    }
  });

  let billingInformationFields = Inputs.filter(
    (inputsData: any) => inputsData?.sectionName === "Billing Information"
  );

  getRepeatedFieldValues(Inputs, obj);

  if (billingInformationFields.length) {
    formValuesForApiBillingInformation(billingInformationFields, obj);
  }

  return mergeSections(obj);
};

function mergeSections(obj: any) {
  const mergedSections: any = {};

  obj.commonSections.forEach((section: any) => {
    if (mergedSections[section.sectionId]) {
      mergedSections[section.sectionId].fields = [
        ...mergedSections[section.sectionId].fields,
        ...section.fields,
      ];
    } else {
      mergedSections[section.sectionId] = {
        sectionId: section.sectionId,
        sectionName: section.sectionName,
        fields: [...section.fields],
      };
    }
  });

  obj.commonSections = Object.values(mergedSections);
  return obj;
}

import axios from "axios";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Decrypt } from "../../Utils/Auth";
import {
  setAdminUserId,
  setFacility,
  setMultiFacilitiesData,
  setSelectedMenu,
  setUserInfo,
} from "../../Redux/Actions/Index";
import { useDispatch } from "react-redux";
import AccountService from "../../Services/Account/AccountService";
import ability from "../../Shared/Common/Permissions/Abilities";
import useLang from "Shared/hooks/useLanguage";

const ChangePassword = () => {
  const { t } = useLang()
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const dispatch = useDispatch();
  const logOutUser = async () => {
    await AccountService.userLogout();
    dispatch(setAdminUserId(null));
    dispatch(setFacility({}));
    dispatch(setUserInfo({}));
    dispatch(setSelectedMenu(null));
    dispatch(setMultiFacilitiesData(null));

    localStorage.clear();
    sessionStorage.clear();

    ability.update([]);
    navigate("/login");
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const localUserInfo = sessionStorage.getItem("userinfo");
      let userInfo: any = "";
      if (localUserInfo) {
        userInfo = JSON.parse(Decrypt(localUserInfo));
      }

      if (newPassword.length >= 8) {
        e.preventDefault();
        const objToSend = {
          userId: userInfo.userId,
          newPassword: newPassword,
          oldPassword: oldPassword,
        };

        axios
          .post(
            `${(window as any)?._env_?.REACT_APP_BASE_URL
            }/api/Account_V2/ChangePassword`,
            objToSend
          )
          .then((res) => {
            if (res?.data?.statusCode === 200) {
              toast.success(res?.data?.message);
              logOutUser();
            } else if (res?.data?.statusCode === 400) {
              toast.error(res?.data?.message);
            } else {
              toast.error(t("something went wrong please try again later"));
            }
          })
          .catch((err: string) => {
            console.trace("tesarbombaaa💣💣💣💣💣💣💣", err);
          });
      } else {
        toast.error(
          t("Use 8 or more characters with a mix of letters, numbers & symbols.")
        );
      }
    } else {
      toast.error(t("Passwords did not match"));
    }
  };
  return (
    <>
      <div>
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          style={{
            backgroundImage: 'url("/media/illustrations/sketchy-1/14.png")',
          }}
        >
          <div
            className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
            style={{ minHeight: "100vh" }}
          >
            <div className="w-lg-560px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form className="form w-100" id="kt_login_signin_form">
                <a href="#" className="mb-12 d-flex justify-content-center">
                  <img
                    alt="Logo"
                    src="/media/logos/logo.png"
                    className="h-45px"
                  />
                </a>
                <div className="text-center mb-5">
                  <h2 className="mb-3">{t("Change Password")}</h2>
                  <Alert variant="danger" className="p-1">
                    {t("Your new Password should not be the same as your current password")}
                  </Alert>
                </div>
                <div className="input-group my-4 mx-4">
                  <input
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="form-control"
                    placeholder={t("Old Password")}
                    minLength={8}
                  />
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => toggleShowOldPassword()}
                    >
                      {showOldPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>

                <div className="input-group my-4 mx-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="form-control"
                    placeholder={t("New Password")}
                    minLength={8}
                  />
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => toggleShowPassword()}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="input-group my-4 mx-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control"
                    placeholder={t("Confirm Password")}
                    minLength={8}
                  />
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => toggleShowPassword()}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                  <button
                    onClick={handleSubmit}
                    // type="button"
                    id="kt_password_reset_submit"
                    className="btn btn-primary me-4 h-45px"
                  >
                    <span className="indicator-label">{t("Submit")}</span>
                    <span className="indicator-progress">
                      {t("Please wait...")}{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                  <Link
                    to="/MyFavourite"
                    className="d-flex align-items-center btn btn-sm fw-bold btn-cancel h-45px"
                  >
                    {t("Cancel")}
                  </Link>
                  {/* <a className="btn btn-light">Cancel</a> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

import React, { useEffect, useRef, useState } from "react";
import Checkbox from "./Checkbox";
import { assignFormValues } from "../../../Utils/Auth";
import { isJson } from "../../../Utils/Common/Requisition";
import { useLocation } from "react-router-dom";

const PanelsCheckboxSpecimenSource = (props: any) => {
  const location = useLocation();
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<any>([]);
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }

  const inputElement = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElement.current ||
      props?.errorFocussedInput === inputElement.current.id
    ) {
      inputElement.current.focus();
    }
  }, [props?.errorFocussedInput]);
  const convertPrefilledIcdDataToJson = () => {
    let inputValue: any[] = [];
    props.panels.map((inner: any) =>
      inner.specimenSourceOption.map(
        (option: any) => option.isSelected && inputValue.push(option)
      )
    );
    let newInputs = assignFormValues(
      props?.Inputs,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      inputValue,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });
  };
  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [location?.state?.reqId && props.defaultValue]);

  const handleChange = (
    e: any,
    options: any,
    checked: boolean,
    panelName: string
  ) => {
    if (checked) {
      let inputValue: any = [];
      let obj = {
        panelID: options?.panelID,
        specimenPreFix: options?.specimenPreFix,
        specimenType: options?.specimenType,
        specimenTypeID: options?.specimenTypeID,
        panelName: panelName,
      };
      inputValue = [...inputValue, obj];
      props.panels?.map((inner: any) =>
        inner?.specimenSourceOption?.map(
          (option: any) => option?.isSelected && inputValue?.push(option)
        )
      );

      let newInputs = assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs.then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData &&
          props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
    if (!checked) {
      setDiagnosisCode((preVal: any) => [
        ...preVal.filter(
          (items: any) => items?.icD10Code != options?.specimenPreFix
        ),
      ]);
      setCheckedBoxes((preVal: any) => [
        ...preVal.filter((items: any) => items != items),
      ]);
      let result = isJson(props.defaultValue.defaultValue);
      let defaultValue = props.defaultValue.defaultValue;
      if (result) {
        defaultValue = JSON.parse(defaultValue);
      }
      let inputValue = defaultValue.filter(
        (checkedPanelsObj: any) =>
          checkedPanelsObj.Code != options?.specimenPreFix
      );

      let newInputs = assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs.then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData &&
          props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{props.error}</span>
        </div>
      )}
      <div id={props?.name} ref={inputElement} tabIndex={-1}></div>
      <div className={props?.sectionDisplayType}>
        <div className="d-flex flex-wrap ">
          {props?.panels?.map(
            (panels: any) =>
              panels?.isVisible && (
                <div
                  className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                >
                  <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h6>{panels?.panelName}</h6>
                    </div>
                  </div>
                  <div className="card-body py-md-4 py-3">
                    <div className="row">
                      {panels?.specimenSourceOption?.map((options: any) => (
                        <>
                          <Checkbox
                            spanClassName="mb-2 mr-2"
                            id={options?.specimenTypeID}
                            label={options.specimenType}
                            testCode={options?.specimenPreFix}
                            checked={options?.isSelected}
                            onChange={(e: any) =>
                              handleChange(
                                e,
                                options,
                                e.target.checked,
                                panels.panelName
                              )
                            }
                            parentDivClassName={
                              "col-xl-12 col-lg-12 col-md-12 col-sm-12"
                            }
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PanelsCheckboxSpecimenSource;

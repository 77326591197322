import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Decrypt, Encrypt } from "../src/Utils/Auth";
import { hideLoader, showLoader } from "./Redux/Actions/Index";
import store from "./Redux/Store/AppStore";
import { getValueFromSessionStorage } from "./Utils/Common/CommonMethods";
import { PortalTypeEnum } from "./Utils/Common/Enums/Enums";
import { isJson } from "./Utils/Common/Requisition";
import { logoutUtil } from "./Utils/UserManagement/UserRoles";

const HttpClient = () => {
  const defaultOptions = {
    baseURL: window.env?.REACT_APP_BASE_URL,
    headers: {
      Accept: "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    store.dispatch(showLoader());
    let userInfo = sessionStorage.getItem("userinfo");
    let facilityInfo = sessionStorage.getItem("facilityInfo");
    let facilityData = facilityInfo;
    if (userInfo) {
      userInfo = Decrypt(userInfo);
      userInfo = JSON.parse(userInfo);
      if (facilityData) {
        // if (facilityData !== "undefined") {
        //   facilityData = Decrypt(facilityData);
        // }
        let result = isJson(facilityData);
        if (result) {
          facilityData = JSON.parse(facilityData);
        }
      }
      config.headers.Authorization = userInfo ? `Bearer ${userInfo.token}` : "";
      config.headers["X-Portal-Key"] = store.getState()?.Reducer?.labKey;
      config.headers["Lab"] =
        store.getState()?.Reducer?.loggedInInfo?.selectedTenantsInfo?.tenantId;
      config.headers["Page-Id"] = getValueFromSessionStorage("pageId");
      if (
        store.getState()?.Reducer?.selectedTenantInfo?.infomationOfLoggedUser
          ?.adminType === PortalTypeEnum.Facility
      ) {
        config.headers["facility"] =
          store.getState()?.Reducer?.facilityData?.facilityId;
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      store.dispatch(hideLoader());
      return response;
    },

    async function (error) {
      store.dispatch(hideLoader());

      const { config } = error;

      if (
        error.response.status === 401 &&
        !config._retry &&
        sessionStorage.getItem("userinfo")
      ) {
        config._retry = true;

        const access = await refreshToken();
        config.headers["Authorization"] = "Bearer " + access;
        return axios(config);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

/**
 * function to make RefreshToken API call which then returns new token.
 */

export const refreshToken = async () => {
  const userInfo = sessionStorage.getItem("userinfo");

  if (!userInfo) {
    return Promise.reject(new Error("No refresh token available"));
  }

  try {
    const parsedUserInfo = JSON.parse(Decrypt(userInfo));
    const response = await axios.post(
      `${window?._env_?.REACT_APP_BASE_URL}/api/Account/RefreshToken`,
      {
        refreshToken: store.getState()?.Reducer?.refreshToken,
      },
      {
        headers: {
          "X-Portal-Key": store.getState()?.Reducer?.labKey,
          Authorization: parsedUserInfo.token,
        },
      }
    );
    const newToken = response.data.token;
    if (!newToken) {
      if (process.env.NODE_ENV === "development") {
        toast.error(JSON.stringify(response.data));
      } else {
        handleLoginRoute();
      }
      return;
    }
    parsedUserInfo.token = newToken;

    const encryptData = Encrypt(JSON.stringify(parsedUserInfo));
    sessionStorage.setItem("userinfo", encryptData);

    return newToken;
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      toast.error(error);
    } else {
      handleLoginRoute(error);
    }

    toast.error(error);
    return Promise.reject(error);
  }
};

const handleLoginRoute = (error) => {
  logoutUtil();
};

function mapStateToProps(state) {
  return { User: state.Reducer };
}

export default connect(mapStateToProps)(HttpClient());

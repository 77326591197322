import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import BreadCrumbs from "Utils/Common/Breadcrumb";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

function DynamicSplitPane() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_toolbar" className="app-toolbar py-2 py-lg-3">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <BreadCrumbs />

          <PanelGroup style={{ height: "90vh" }} direction="horizontal">
            <Panel>
              <PanelGroup direction="vertical">
                <Panel style={{ flexGrow: 10 }}>
                  The filter section will be displayed here.
                </Panel>
                <PanelResizeHandle className="border" />
                <Panel>
                  <PanelGroup direction="horizontal">
                    <Panel>
                      <LeftPanel />
                    </Panel>
                    <PanelResizeHandle className="border" />
                    <Panel>
                      <RightPanel />
                    </Panel>
                  </PanelGroup>
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
}

export default DynamicSplitPane;

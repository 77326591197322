import { SetStateAction, useEffect, useState } from "react";
import { Country } from "../../Interface/Shared/AutoComplete";
import AssigmentService from "../../Services/AssigmentService/AssigmentService";
import { useDebounce } from "usehooks-ts";
import PatientServices from "../../Services/PatientServices/PatientServices";

const useAutocomplete = (inputSearchRef: HTMLInputElement | null) => {
  const [searchedValue, setSearchedValue] = useState<any>("");
  const debouncedValue = useDebounce<string>(searchedValue, 500);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [focusOut, setFocusOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [apiCallDetails, setapiCallDetails] = useState({
    apiCallName: "",
    facilityId: "",
    sysytemFieldName: "",
  });
  useEffect(() => {
    if (inputSearchRef) {
      inputSearchRef.focus();
    }
  }, []);

  useEffect(() => {
    apiCall(searchedValue, apiCallDetails.facilityId ?? 0);
  }, [debouncedValue]);

  const apiCall = (query: SetStateAction<string>, key: number | string) => {
    if (!touched) return;
    setLoading(true);
    let fId = localStorage.getItem("facilityID");
    if (apiCallDetails?.apiCallName === "Patient" && searchedValue) {
      let obj = {
        FirstName:
          apiCallDetails.sysytemFieldName === "FirstName" ? searchedValue : "",
        LastName:
          apiCallDetails.sysytemFieldName === "LastName" ? searchedValue : "",
        facilityId: fId ?? 0,
      };
      PatientServices.getPatientDetailEitherByFirstNameOrLastname(obj)
        .then((res: any) => {
          setSuggestions(res?.data?.data);
        })
        .catch((err: any) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      AssigmentService.SearchICD10(query, key)
        .then((res: any) => setSuggestions(res?.data))
        .catch((err: any) => console.log(err))
        .finally(() => setLoading(false));
    }
  };
  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }): void => {
    setTouched(true);
    if (event.target.value !== "") {
      setSearchedValue(event.target.value);
      // const filteredSuggestions = data.filter(itemData => {
      // 	const value = event.target.value.toString().toUpperCase()
      // 	const name = itemData.name.common.toUpperCase()

      // 	return value && name.startsWith(value) && name !== value
      // })
    } else {
      setSearchedValue("");
      setSuggestions([]);
      setSelectedSuggestion("");
      setActiveSuggestion(0);
    }
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "ArrowDown" && activeSuggestion < suggestions.length) {
      setActiveSuggestion(activeSuggestion + 1);
    } else if (event.key === "ArrowUp" && activeSuggestion > 1) {
      setActiveSuggestion(activeSuggestion - 1);
    } else if (event.key === "Enter") {
      setSearchedValue(suggestions[activeSuggestion - 1].name.common);
      setSelectedSuggestion(suggestions[activeSuggestion - 1].name.common);
      setSuggestions([]);
      setActiveSuggestion(0);
    }
  };

  const handleClick = (value: string) => {
    setSearchedValue(value);
    setSuggestions([]);
    setSelectedSuggestion(value);
    setActiveSuggestion(0);
    setTouched(false);
    //do something else
  };

  return {
    setTouched,
    setActiveSuggestion,
    setSelectedSuggestion,
    searchedValue,
    setSearchedValue,
    setapiCallDetails,
    setSuggestions,
    suggestions,
    selectedSuggestion,
    activeSuggestion,
    loading,
    touched,
    handleChange,
    handleKeyDown,
    handleClick,
    focusOut,
  };
};

export default useAutocomplete;

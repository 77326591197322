import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Select from "react-select";
import { reactSelectStyle, styles } from "../../Utils/Common";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Facilities from "../Facility/FacilityUser/CreateFacilityUser/Facilities";
import AdditionalUserRole from "../Admin/UserManagement/AdditionalUserRole";
import InputMask from "react-input-mask";
import useLang from "Shared/hooks/useLanguage";
export interface NavLinkProps<T> {
  NavigatorsArray: T[];
  AddBtnText: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  Inputs: any;
  searchRequest: any;
  setSearchRequest: any;
  loadData: any;
  statusDropDownName: string;
  handleOpen: any;
  handleOnChange: any;
  values: any;
  errors: any;
  setErrors: any;
  editGridHeader: any;
  PanelSetupList: any;
  setPanelSetupList: any;
  openModal: any;
  setValues: any;
  modalheader: any;
  setEditGridHeader: any;
  handleSubmit: any;
  setRequest: any;
  request: any;
  panels: any;
  setPanels: any;
  sports2: any;
  setSports2: any;
  selectedPanels: any;
  setSelectedPanels: any;
  open: any;
  setOpen: any;
  facilities: any;
  GetDataAgainstRoles: any;
  checkboxes: any;
  setCheckboxes: any;
  roletype: any;
  valFacility: any;
  setValFacility: any;
}
export interface LinksArray {
  text: string;
  link: string;
}

const GridNavbar: React.FC<NavLinkProps<LinksArray>> = ({
  setOpenModal,
  values,
  setErrors,
  editGridHeader,
  openModal,
  setValues,
  modalheader,
  setEditGridHeader,
  handleSubmit,
  setRequest,
  sports2,
  setSports2,
  loadData,
  setSelectedPanels,
  facilities,
  GetDataAgainstRoles,
  checkboxes,
  setCheckboxes,
  roletype,
  valFacility,
  setValFacility,
}) => {
  const { t } = useLang()
  const [show, isShow] = useState<boolean>(false);
  Yup.addMethod(Yup.string, "phone", function (errorMessage: string) {
    return this.test("test-phone", errorMessage, function (value) {
      const { path, createError } = this;
      if (value === undefined || value === null) {
        return createError({ path, message: errorMessage });
      }
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      return (
        phoneRegex.test(value) || createError({ path, message: errorMessage })
      );
    });
  });
  //Formik Validation
  const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

  const validationSchema = Yup.object({
    positionTitle: Yup.string().required("Position Title is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid"),
    salesEmail: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    salesRepNumber: Yup.string().required("Sales Rep No is required"),
    adminType: Yup.string().required("Role Type is required"),
  });
  const [dragSports, setDragSports] = useState<any>([]);
  const [dragSports2, setDragSports2] = useState<any>([]);
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <Collapse in={openModal}>
        <div className="app-content flex-column-fluid">
          <div className="app-container container-fluid ">
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                setFieldValue,
                values,
                isSubmitting,
                handleChange,
                resetForm,
              }) => (
                <Form>
                  <div id="ModalCollapse" className="card">
                    <div className="align-items-center bg-light-warning card-header d-flex justify-content-center justify-content-sm-between gap-3 minh-42px">
                      <h5 className="m-0 ">{modalheader}</h5>
                      <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <button
                          className="btn btn-secondary btn-sm fw-bold "
                          aria-controls="SearchCollapse"
                          type="button"
                          aria-expanded="true"
                          onClick={() => {
                            setOpenModal(false);
                            setEditGridHeader(false);
                            setSports2([]);
                            setCheckboxes([]);
                            setRequest(false);
                            resetForm();
                            loadData(true);
                            setValues({
                              id: "",
                              positionTitle: "",
                              firstName: "",
                              lastName: "",
                              salesRepPhone: "",
                              salesEmail: "",
                              salesRepNumber: "",
                              adminType: "",
                            });
                          }}
                        >
                          <span>
                            <i className="fa fa-times"></i>
                            <span>{t("cancel")}</span>
                          </span>
                        </button>
                        <button
                          className="btn btn-primary btn-sm btn-primary--icon px-7"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                    <div id="form-search" className=" card-body py-2 py-md-3">
                      <div className="row">
                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold required">
                              {t("Position Title")}
                            </label>
                            <div className="mb-2">
                              <Field
                                className="form-control"
                                type="text"
                                name="positionTitle"
                                placeholder="Position Title"
                              />
                              <ErrorMessage
                                name="positionTitle"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold required">
                              {t("First Name")}
                            </label>
                            <div className="mb-2">
                              <Field
                                className="form-control"
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold required">
                              {t("Last Name")}
                            </label>
                            <div className="mb-2">
                              <Field
                                className="form-control"
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold">{t("Phone")}</label>
                            <div className="mb-2">
                              <InputMask
                                mask="(999) 999-9999"
                                value={values.phone || ""}
                                onChange={handleChange}
                                name="phone"
                                className="form-control"
                                placeholder="(xxx) xxx-xxxx"
                              ></InputMask>
                              <ErrorMessage
                                name="phone"
                                className="text-danger"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold required">{t("Email")}</label>
                            <div className="mb-2">
                              <Field
                                className="form-control"
                                type="text"
                                name="salesEmail"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="salesEmail"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <label className="mr-2 mb-2 fw-bold required">
                              {t("Sales Rep No")}
                            </label>
                            <div className="mb-2">
                              <Field
                                className="form-control"
                                type="text"
                                name="salesRepNumber"
                                placeholder="Sales Rep No"
                              />
                              <ErrorMessage
                                name="salesRepNumber"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                            <div className="fv-row mb-4">
                              <label className="required mb-2 fw-bold">
                                {t("Role Type")}
                              </label>
                              <Select
                                styles={reactSelectStyle}
                                theme={(theme) => styles(theme)}
                                options={roletype}
                                name="adminType"
                                placeholder="Select Role Type"
                                value={roletype.filter(
                                  (option: any) =>
                                    option.value === values.adminType.toString()
                                )}
                                isDisabled={editGridHeader}
                                onChange={(option) => {
                                  setFieldValue(
                                    "adminType",
                                    option ? option.value : ""
                                  );

                                  GetDataAgainstRoles(option.value);
                                }}
                                isSearchable={true}
                                isClearable={true}
                                className="z-index-3"
                              />
                              <ErrorMessage
                                name="adminType"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <AdditionalUserRole
                            checkboxes={checkboxes}
                            setCheckboxes={setCheckboxes}
                          />
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Facilities
                              setDragSports={setDragSports}
                              setDragSports2={setDragSports2}
                              facilities={facilities}
                              values={valFacility}
                              setValues={setValFacility}
                              setSports2={setSports2}
                              sports2={sports2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default GridNavbar;

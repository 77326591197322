import { useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { refreshToken } from "../../HttpClient";
import { getToken } from "../../Utils/Auth";
import useIdleTimeout from "../hooks/useIdle";
import useLogoutListener from "../hooks/useLogoutListener";
import CountdownTimer from "./CountdownTimer";

export default function AuthDialog() {
  const idleTime = 300000;
  const [openModal, setOpenModal] = useState(false);
  const token = getToken();
  const logout = useLogoutListener();

  const handleIdle = () => {
    if (token) {
      setOpenModal(true);
    }
  };

  const handleLogout = async () => {
    logout();
    setOpenModal(false);
  };

  const { reset } = useIdleTimeout({
    onIdle: handleIdle,
    onLogout: handleLogout,
    idleTime
  });

  const Stay = async () => {
    // Reset the idle timer
    reset();
    await refreshToken();
    setOpenModal(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BootstrapModal
      show={openModal}
      backdrop="static"
      keyboard={false}
      fullScreen={fullScreen}
    >
      <BootstrapModal.Body>
        <CountdownTimer initialTime={idleTime} isActive={openModal} />
        <span className="d-flex align-items-center flex-column mt-5 font-weight-bold">
          Your session is about to expire...
        </span>
      </BootstrapModal.Body>
      <div className="d-flex align-items-center justify-content-center gap-5 pb-10">
        <button className="btn btn-sm btn-primary" autoFocus onClick={Stay}>
          Continue
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={handleLogout}
          autoFocus
        >
          Logout
        </button>
      </div>
    </BootstrapModal>
  );
}

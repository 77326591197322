import { toast } from "react-toastify";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

const printBarcode = async (labelData: any) => {
  console.log(labelData, "labelData");

  try {
    const { firstName, lastName, order, dob, accessionNumber, labCode } =
      labelData;
    const currentDateInUTC = new Date().toUTCString();

    const xPosition = 150; // Center position

    const zpl = `^XA
    ^CF0,30
    ^FO${xPosition},50^FDName: ${firstName} ${lastName}^FS
    ^FO${xPosition},80^FDDOB: ${dob}^FS
    ^FO${xPosition},110^FDOrder No: ${order}^FS
    ^FO${xPosition},140^FDLab: ${labCode}^FS
    ^FO${xPosition},170^FD${currentDateInUTC}^FS
    ^FO${xPosition},200^BY2,2,300^BCN,100,Y,N,N^FD${accessionNumber}^FS
    ^XZ`;

    const browserPrint = new ZebraBrowserPrintWrapper();
    const defaultPrinter = await browserPrint.getDefaultPrinter();
    browserPrint.setPrinter(defaultPrinter);
    const printerStatus = await browserPrint.checkPrinterStatus();
    console.log(defaultPrinter, "defaultPrinter");

    if (printerStatus.isReadyToPrint) {
      browserPrint.print(zpl);
    } else {
      toast.error(printerStatus.errors);
    }
  } catch (error) {
    toast.error("Error Printing Labels");
  }
};

export default printBarcode;

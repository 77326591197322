import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import useLang from "../hooks/useLanguage";
import {
  getValueFromSessionStorage,
  setValueIntoSessionStorage,
} from "../../Utils/Common/CommonMethods";

export default function TranslationMenu({ handleClose }: any) {
  const { changeLanguage, i18n } = useLang();
  const [language, setLanguage] = React.useState(
    getValueFromSessionStorage("lng") || "en"
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    setValueIntoSessionStorage("lng", selectedLanguage);
    changeLanguage(selectedLanguage);
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
    handleClose();
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Translation Language
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label="Translation Language"
          onChange={handleChange}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="es">Spanish</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

import { genUniqueId } from "../Utils/Common";
import DynamicFormInputs from "./DynamicFormInputs";
import { CrossIcon } from "./Icons";

const RepeatInputs = (props: any) => {
  const addFields = (
    repeatFieldsState: any,
    repeatDependencyControlsState: any,
    index: number,
    Inputs: any,
    fieldIndex: number,
    addCount: number
  ) => {
    let inputsCopy = JSON.parse(JSON.stringify(Inputs));
    let copyRepeatField = JSON.parse(
      JSON.stringify(inputsCopy[index].fields[fieldIndex])
    );
    let id = genUniqueId();
    //copyRepeatField.repeatFields.unshift(crossObj);
    copyRepeatField.id = id;
    copyRepeatField?.repeatDependencyControls?.forEach(
      (element1: any, depindex: number, arrayItself: any) => {
        arrayItself[depindex].dependecyFields.forEach(
          (element2: any, DepFieldindex: number, arrayItselDepFields: any) => {
            if (
              arrayItselDepFields[DepFieldindex]?.displayType.includes("d-none")
            ) {
              // return;
            } else {
              arrayItselDepFields[DepFieldindex].displayType =
                arrayItselDepFields[DepFieldindex].displayType + " " + "d-none";
            }
          }
        );
      }
    );
    copyRepeatField.repeatFields.forEach(
      (element1: any, repeatfieldIndex: number, arrayItself: any) => {
        if (arrayItself[repeatfieldIndex]?.displayType.includes("d-none")) {
          let removeDisplayNone = arrayItself[
            repeatfieldIndex
          ]?.displayType.replace("d-none", " ");
          arrayItself[repeatfieldIndex].displayType = removeDisplayNone;
        }
      }
    );

    copyRepeatField.repeatFields?.forEach((repeatFieldInfo: any) => {
      repeatFieldInfo.defaultValue = "";
    });
    var json = JSON.stringify(copyRepeatField);

    var newCopyWithOutRef = JSON.parse(json);

    inputsCopy[index].fields.splice(fieldIndex + 1, 0, newCopyWithOutRef);
    props?.setInputs(inputsCopy);
    if (fieldIndex > 0) {
      copyRepeatField.repeatFields.shift();
    }
  };

  const fields = props.Inputs[props.index].fields;
  const repeatIndexes = fields.reduce((indexes: any, item: any, idx: any) => {
    if (item?.uiType === "Repeat") {
      indexes.push(idx);
    }
    return indexes;
  }, []);

  const removeFields = (billingInfoSectionid: number, fieldsId: number) => {
    let inputsCopy = [...props.Inputs];
    inputsCopy[fieldsId].fields.splice(billingInfoSectionid, 1);
    props?.setInputs && props?.setInputs(inputsCopy);
  };

  return (
    <>
      <span
        className={
          props.fieldIndex == repeatIndexes[0]
            ? "d-none"
            : "d-flex justify-content-end"
        }
      >
        <span onClick={() => removeFields(props?.fieldIndex, props?.index)}>
          <CrossIcon className="fs-2hx text-gray-700 bi bi-x cursor-pointer" />
        </span>
      </span>
      {props.fieldIndex === repeatIndexes[0] &&
      props.requisitionflow !== "requisitionbilling" ? (
        <hr />
      ) : null}
      {props?.repeatFields?.map((field: any, index: number) => (
        <>
          <DynamicFormInputs
            uiType={field?.uiType}
            removeUi={field?.removeUi ? field?.removeUi : false}
            label={field?.displayFieldName}
            displayType={field?.displayType}
            visible={field?.visible}
            required={field?.required}
            RadioOptions={field?.uiType === "RadioButton" ? field?.options : ""}
            formData={props?.formData}
            setFormData={props?.setFormData}
            selectOpt={field?.options}
            formState={props?.formState}
            setFormState={props?.setFormState}
            index={props?.index}
            fieldIndex={props?.fieldIndex}
            repeatFieldIndex={index}
            Inputs={props?.Inputs}
            setInputs={props?.setInputs}
            sysytemFieldName={field?.systemFieldName}
            //setShowHideFields={setShowHideFields}
            isDependent={false}
            dependenceyControls={props?.Section?.dependenceyControls}
            repeatFields={field?.repeatFields}
            //dependency={dependency}
            //setDependency={setDependency}
            //RemoveFields={RemoveFields}
            searchID={field?.searchID}
            // comp="fields"
            isShown={props.isShown}
            setIsShown={props.setIsShown}
            repeatInputs={true}
            pageId={props?.pageId}
            repeatFieldSection={true}
            sectionName={props?.sectionName}
            defaultValue={field?.defaultValue ?? ""}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            enableRule={field?.enableRule}
            controlId={field?.controlId}
            mask={field.mask}
            errorFocussedInput={props?.errorFocussedInput}
            field={field}
            //addFields={addFields}
            //ref={ref}
          />
        </>
      ))}
      {props?.repeatDependencyControls?.map(
        (options: any, repeatDependencySectionIndex: number) => (
          <>
            {options?.dependecyFields?.map(
              (depfield: any, repeatDepFieldIndex: number) => (
                <>
                  <DynamicFormInputs
                    uiType={depfield?.uiType}
                    label={depfield?.displayFieldName}
                    sysytemFieldName={depfield?.systemFieldName}
                    displayType={
                      depfield?.displayType +
                      " " +
                      options?.name +
                      " " +
                      options?.name +
                      options.optionID
                    }
                    visible={depfield?.visible}
                    required={depfield?.required}
                    RadioOptions={
                      depfield?.uiType === "RadioButton"
                        ? depfield?.options
                        : ""
                    }
                    formData={props?.formData}
                    setFormData={props?.setFormData}
                    formState={props?.formState}
                    setFormState={props?.setFormState}
                    index={props?.index}
                    repeatDependencySectionIndex={repeatDependencySectionIndex}
                    repeatDepFieldIndex={repeatDepFieldIndex}
                    fieldIndex={props?.fieldIndex}
                    Inputs={props?.Inputs}
                    setInputs={props?.setInputs}
                    depOptionID={options.optionID}
                    //setShowHideFields={setShowHideFields}
                    dependenceyControls={props?.Section?.dependenceyControls}
                    isDependent={true}
                    searchID={depfield?.searchID}
                    //RemoveFields={RemoveFields}
                    dependencyAction={options?.dependecyAction}
                    // comp="dependency"
                    isShown={props.isShown}
                    setIsShown={props.setIsShown}
                    //addFields={addFields}
                    //ref={ref}
                    depfield={depfield}
                    repeatInputs={true}
                    pageId={props?.pageId}
                    sectionName={props?.sectionName}
                    repeatFieldSection={true}
                    isDependencyRepeatFields={true}
                    defaultValue={depfield?.defaultValue ?? ""}
                    setInfectiousData={props.setInfectiousData}
                    enableRule={depfield.enableRule}
                    mask={depfield.mask}
                    errorFocussedInput={props?.errorFocussedInput}
                  />
                </>
              )
            )}
          </>
        )
      )}
      <div className="col-12">
        <hr />
        <button
          onClick={(e: any) => {
            addFields(
              props?.repeatFieldsState,
              props?.repeatDependencyControlsState,
              props?.index,
              props?.Inputs,
              props?.fieldIndex,
              1
            );
          }}
          className={`${
            props.fieldIndex == repeatIndexes[repeatIndexes.length - 1]
              ? ""
              : "d-none"
          } btn btn-info`}
        >
          <i style={{ fontSize: "16px" }} className="fa">
            &#xf067;
          </i>
          {props.Inputs[props?.index].fields[props?.fieldIndex].mask
            ? props.Inputs[props?.index].fields[props?.fieldIndex].mask
            : "Add Another"}
        </button>
      </div>
    </>
  );
};

export default RepeatInputs;

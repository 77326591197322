import React from "react";
import { connect } from "react-redux";
import { setFacility, setFacilityClaims } from "../../Redux/Actions/Index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ability from "../../Shared/Common/Permissions/Abilities";
import { useSelector } from "react-redux";
import useLang from "Shared/hooks/useLanguage";

const SelectFacility = (props: any) => {
  const { t } = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTenantInfo = useSelector(
    (state: any) => state.Reducer.selectedTenantInfo
  );

  const SetFacilityInfoAndNavigateToFavourite = (facilityInfo: any) => {
    dispatch(setFacility(facilityInfo));
    dispatch(
      setFacilityClaims(selectedTenantInfo?.infomationOfLoggedUser.claims)
    );
    ability.update([]);
    ability.update(selectedTenantInfo?.infomationOfLoggedUser.permissions);
    navigate("/MyFavourite");
  };

  console.log(
    props?.User?.Reducer?.facilityInfo,
    "props?.User?.Reducer?.facilityInfo"
  );

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid position-x-center">
        <div className="d-flex flex-center flex-column flex-column-fluid">
          <div className="mx-auto px-6 w-100">
            <div className="card w-100 mt-8 ">
              <div className="card-header d-flex align-items-center p-6">
                <div className=" d-flex align-items-center justify-content-start">
                  <img
                    src={process.env.PUBLIC_URL + "/media/menu-svg/lab.svg"}
                    alt="select-lab"
                  />
                  <h3 className="text-dark mx-4 mb-0">{t("Select a Facility")}</h3>
                </div>
              </div>
            </div>
            <div className="row mt-7">
              {props?.User?.Reducer?.facilityInfo?.map(
                (facilityInfo: any, i: any) => (
                  <div
                    key={facilityInfo?.facilityId}
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-10"
                  >
                    <div className="card h-225px">
                      <div className="card-body d-flex flex-column justify-content-end align-items-center">
                        {/* <img src={lab.logo} alt="" className="img-fluid" /> */}
                        <div className="text-dark fs-2 text-center">
                          {facilityInfo.facilityName}
                        </div>
                        <span className="mb-5">{facilityInfo.facilityAddress}</span>
                        <div className="d-flex">
                          <button
                            type="button"
                            onClick={() =>
                              SetFacilityInfoAndNavigateToFavourite(
                                facilityInfo
                              )
                            }
                            className="btn btn-light-primary"
                          >
                            <i className="bi bi-box-arrow-in-right"></i> {t("Login")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return { User: state };
}
export default connect(mapStateToProps)(SelectFacility);

import axios from "axios";
import HttpClient from "../../HttpClient";
import { IApiResponse, IUserInfo } from "../../Interface/Shared/ApiResponse";
import store from "../../Redux/Store/AppStore";
import routes from "../../Routes/Routes.json";

export let REACT_APP_BASE_URL = (window as any)?.env?.REACT_APP_BASE_URL;

const userLogin = async (user: any) => {
  const response: IApiResponse<IUserInfo> = await axios.post(
    `${REACT_APP_BASE_URL}/${routes.Login}`,
    user
  );
  return response;
};

const switchPortal = async (key: string, token: string, labId: number) => {
  const response = await axios.post(
    `${REACT_APP_BASE_URL}/${routes.UserManagement.SelectTenantLogin}?labId=${labId}`,
    null,
    {
      headers: {
        "X-Portal-Key": key,
        Authorization: `Bearer ${token}`,
        Lab: labId,
      },
    }
  );

  return response;
};

const userLogout = () => {
  return (HttpClient as any).post(`${REACT_APP_BASE_URL}/${routes.Logout}`, {
    refreshToken: (store as any).getState()?.Reducer?.refreshToken,
  });
};

const AccountService = {
  userLogin,
  userLogout,
  switchPortal,
};
export default AccountService;

import HttpClient from "../../HttpClient.js";
import apiRoutes from "../../Routes/Routes.json";

const GetAllNotification = (obj) => {
  return HttpClient.post(
    `/${apiRoutes.MiscellaneousManagement.GetAllNotification}`,
    obj
  );
};
const SaveNotification = (obj) => {
  return HttpClient.post(
    `/${apiRoutes.MiscellaneousManagement.SaveNotification}`,
    obj
  );
};
const getAllUserLookup = () => {
  return HttpClient.get(
    `/${apiRoutes.MiscellaneousManagement.getAllUserLookup}`
  );
};

/**
 * DynamicSingleGrid
 */
const singleGridTabsConfiguration = (tableId) => {
  return HttpClient.get(
    `/${apiRoutes.MiscellaneousManagement.singleGridTabsConfiguration}/${tableId}/SingleUI`
  );
};

const getTablesSingleGrid = () => {
  return HttpClient.get(
    `/${apiRoutes.MiscellaneousManagement.getTablesSingleUi}`
  );
};

const getSingleUiDynamicGrid = (payload) => {
  return HttpClient.post(
    `/${apiRoutes.MiscellaneousManagement.getSingleUiDynamicGrid}`,
    payload
  );
};

const saveSingleUiColumn = (payload) => {
  return HttpClient.post(
    `/${apiRoutes.MiscellaneousManagement.saveSingleUiColumn}`,
    payload
  );
};

const MiscellaneousService = {
  GetAllNotification,
  SaveNotification,
  getAllUserLookup,
  singleGridTabsConfiguration,
  getTablesSingleGrid,
  getSingleUiDynamicGrid,
  saveSingleUiColumn,
};

export default MiscellaneousService;

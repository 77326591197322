import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RequisitionType from "../../../../Services/Requisition/RequisitionTypeService";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import printBarcode from "../../../Printing/ZebraPrint";

const RequisitionSummary = (props: any) => {
  const location = useLocation();
  const apiResponse = location?.state;
  const ShowBlob = (Url: string) => {
    RequisitionType.ShowBlob(Url).then((res: any) => {
      window.open(res?.data?.Data.replace("}", ""), "_blank");
    });
  };
  const PdfDownload = async () => {
    const obj = {
      reqId: apiResponse?.data?.requisitionID,
      requisitionOrderId: 0,
    };
    RequisitionType.ShowOrderViewPdf(obj)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          ShowBlob(res.data.data);
          toast.success(res.data.message);
        }
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };
  const ValidatedDate = moment(apiResponse?.data?.patientDateOfBirth)?.format(
    "MM/DD/YYYY"
  );
  const navigate = useNavigate();

  const PrintLabel = (option: any, item: any) => {
    const obj = {
      firstName: apiResponse?.data?.firstName,
      lastName: apiResponse?.data?.lastName,
      order: apiResponse?.data?.orderNo,
      dob: ValidatedDate,
      accessionNumber: apiResponse?.data?.accessionNos
        ? apiResponse?.data?.accessionNos
        : "",
      labCode: props?.User?.labinfo?.name,
    };
    printBarcode(obj);
    // if (option === "Zebra") {

    // }
    // if (option === "Dynmo" || "Dymo" || "dymo") {
    // } else {
    //   toast.error("Configuration not available");
    // }
  };
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="app-toolbar py-3 py-lg-6">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <ul className="breadcrumb breadcrumb-separatorless  fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="" className="text-muted text-hover-primary">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">Requisition</li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">New Requisition</li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                Requisition Summary
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <h5>Requisition Summary </h5>
                <span className="text-primary fw-bold">
                  {" "}
                  (Please write the following information on the provided{" "}
                  {props?.User?.labinfo?.name} . Please place the card along
                  with sample)
                </span>
              </div>
            </div>
            <div className="card-body py-md-4 py-3">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                  <label className="required mb-2 fw-500">First Name</label>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="firstName"
                    className="form-control bg-transparent"
                    value={apiResponse?.data?.firstName}
                    disabled
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                  <label className="required mb-2 fw-500">Last Name</label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                    className="form-control bg-transparent"
                    value={apiResponse?.data?.lastName}
                    disabled
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                  <label className="required mb-2 fw-500">
                    Patient Date of Birth
                  </label>
                  <input
                    placeholder="Date Of Birth"
                    type="text"
                    name="patientDateOfBirth"
                    className="form-control bg-transparent"
                    value={ValidatedDate}
                    disabled
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                  <label className="required mb-2 fw-500">Order no</label>
                  <input
                    placeholder="Order Number"
                    type="text"
                    name="orderNo"
                    className="form-control bg-transparent"
                    value={apiResponse?.data?.orderNo}
                    disabled
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                  <label className="required mb-2 fw-500">Accession no</label>
                  <input
                    placeholder="Accession no"
                    type="text"
                    name="accessionNos"
                    className="form-control bg-transparent"
                    value={apiResponse?.data?.accessionNos}
                    disabled
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-start gap-lg-3 gap-2 align-items-center mt-7">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    navigate(`/Pending-requisition`);
                  }}
                >
                  Incomplete Requisition
                </button>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={PdfDownload}
                >
                  Print Requisition
                </button>
                <button
                  className="btn btn-warning btn-sm"
                  onClick={() => {
                    PrintLabel("Zebra", apiResponse);
                  }}
                >
                  Print Label
                </button>
                <Link className="btn btn-info btn-sm" to="/requisition">
                  New Requisition
                </Link>
                <Link className="btn btn-success btn-sm" to="/view-requisition">
                  View Requisition
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state: any) {
  return { User: state.Reducer };
}
export default connect(mapStateToProps)(RequisitionSummary);

import { TableCell } from "@mui/material";
import moment from "moment";
import Select from "react-select";
import { reactSelectSMStyle, styles } from "../../Utils/Common";

enum FilterTypeColumns {
  Text = "text",
  Datepicker = "datepicker",
  Dropdown = "dropdown",
}

function DynamicOneUiInputs({
  column,
  item,
  handleInputsChange,
  columnHeaderIndex,
  inputFields,
}: any) {
  const handleChange = (e: any) => {
    let value = e.target.value;
    let type = e.target.type;
    if (type === "date") {
      value = moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
    }
  };

  const filteredData = inputFields
    .map((item: any, index: number) =>
      item && item.jsonOptionData ? { ...item, defaultIndex: index } : null
    )
    .filter((item: any) => item !== null);

  const JSONOptions = filteredData.find(
    (item: any) => item.defaultIndex === columnHeaderIndex
  );

  let dropdownOptions: any = "";
  if (JSONOptions) {
    dropdownOptions = JSON.parse(JSONOptions.jsonOptionData ?? "{}");
  }

  return (
    <TableCell>
      {column?.filterColumnsType?.toLowerCase() === FilterTypeColumns.Text && (
        <input
          type="text"
          className="form-control bg-white mb-lg-0 h-30px rounded-2 w-100"
          placeholder={column?.columnLabel}
          value={item?.[column?.columnKey]}
          name={column?.columnKey}
          onChange={(e: any) =>
            handleInputsChange(e.target.name, e.target.value, item?.Id)
          }
        />
      )}
      {column?.filterColumnsType?.toLowerCase() ===
        FilterTypeColumns.Dropdown && (
        <Select
          theme={(theme) => styles(theme)}
          name={column?.columnKey}
          value={
            dropdownOptions?.length &&
            dropdownOptions?.filter((option: any) => {
              return option.value === item?.[column?.columnKey];
            })
          }
          options={dropdownOptions}
          styles={reactSelectSMStyle}
          onChange={(e: any) =>
            handleInputsChange(column?.columnKey, e.value, item?.Id)
          }
        />
      )}
      {column?.filterColumnsType?.toLowerCase() ===
        FilterTypeColumns.Datepicker && (
        <input
          className="form-control bg-white mb-lg-0 h-30px rounded-2 fs-8 w-100"
          type="date"
          value={convertToDateInputFormat(item?.[column?.columnKey])}
          onChange={(e: any) =>
            handleInputsChange(column?.columnKey, e.target.value, item?.Id)
          }
        />
      )}
    </TableCell>
  );
}

export default DynamicOneUiInputs;

const convertToDateInputFormat = (dateString: string) => {
  if (!dateString) return "";

  const [month, day, year] = dateString.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

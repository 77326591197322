// import React, { useEffect, useRef, useState } from "react";
// import Select from "react-select";
// import { stateDropdownArray, styles } from "../../../Utils/Common";
// import InsuranceService from "../../../Services/InsuranceService/InsuranceService";
// import Popup from "../../../Shared/Modal/ViewRequisitionPopup";
// import { assignFormValues } from "../../../Utils/Auth";

// const ReactSelect = (props: any) => {
//   const {
//     Inputs,
//     dependenceyControls,
//     index,
//     depControlIndex,
//     fieldIndex,
//     inputValue,
//     isDependency,
//     repeatFieldSection,
//     isDependencyRepeatFields,
//     repeatFieldIndex,
//     repeatDependencySectionIndex,
//     repeatDepFieldIndex,
//     ControlType,
//   } = props;
//   const [dropdownOpts, setDropdownOpts] = useState<any>();
//   const [openModal, setOpenModal] = useState(false);
//   const [selectedOption, setSelectedOption] = useState("");
//   const fetchedOptions = useRef(false);
//   console.log(inputValue, "propsInsuranceHeader");

//   useEffect(() => {
//     if (ControlType === "InsuranceProviderControl" && !fetchedOptions.current) {
//       getOptions();
//     }
//   }, [ControlType]);
//   const inputElementReactSelect = useRef(props?.name);
//   useEffect(() => {
//     if (
//       props?.errorFocussedInput === inputElementReactSelect.current ||
//       props?.errorFocussedInput === inputElementReactSelect.current.id
//     ) {
//       inputElementReactSelect.current.focus();
//     }
//   }, [props?.errorFocussedInput]);

//   const getOptions = async () => {
//     fetchedOptions.current = true;
//     try {
//       const res = await InsuranceService.GetInsuranceProvidersDropdown(0);
//       setDropdownOpts(res?.data);
//       const selected = res?.data?.find(
//         (x: any) => x?.value === parseInt(inputValue)
//       );
//       if (selected?.value !== 0) {
//         setSelectedOption(selected);
//         assignFormValues(
//           Inputs,
//           dependenceyControls,
//           index,
//           depControlIndex,
//           fieldIndex,
//           selected?.value,
//           isDependency,
//           repeatFieldSection,
//           isDependencyRepeatFields,
//           repeatFieldIndex,
//           repeatDependencySectionIndex,
//           repeatDepFieldIndex,
//           selectedOption,
//           props?.setInputs
//         );
//       }
//     } catch (err) {
//       console.error("Error fetching insurance providers", err);
//     }
//   };

//   useEffect(() => {
//     setPrefilledValue();
//   }, [inputValue, dropdownOpts]);

//   const setPrefilledValue = () => {
//     const selected = inputValue
//       ? dropdownOpts?.find((x: any) => x.value === inputValue)
//       : "";
//     setSelectedOption(selected);
//   };
//   const SavePatientInsuranceProvider = async () => {
//     const obj = {
//       insuranceProviderId: 0,
//       providerName: "string",
//       address1: "string",
//       address2: "string",
//       city: "string",
//       state: "string",
//       zipCode: "string",
//       landPhone: "string",
//       providerCode: "string",
//       providerStatus: true,
//     };
//     try {
//       const res = await InsuranceService.addPatientInsuranceProvider(obj);
//     } catch (err) {
//       console.error("Error saving insurance providers", err);
//     }
//   };
//   return (
//     <>
//       <div id={props?.name} ref={inputElementReactSelect} tabIndex={-1}></div>
//       <div
//         className={
//           props?.parentDivClassName
//             ? `${props?.parentDivClassName} mb-5`
//             : "col-lg-6 col-md-6 col-sm-12 mb-5"
//         }
//       >
//         <label
//           className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
//         >
//           {props?.label}
//         </label>
//         <Select
//           options={dropdownOpts}
//           placeholder={props?.label}
//           theme={(theme) => styles(theme)}
//           value={selectedOption}
//           onChange={(e: any) => {
//             setSelectedOption(e);
//             assignFormValues(
//               Inputs,
//               dependenceyControls,
//               index,
//               depControlIndex,
//               fieldIndex,
//               e.value,
//               isDependency,
//               repeatFieldSection,
//               isDependencyRepeatFields,
//               repeatFieldIndex,
//               repeatDependencySectionIndex,
//               repeatDepFieldIndex,
//               e.label,
//               props?.setInputs
//             );
//           }}
//           isSearchable={props?.isSearchable}
//           styles={{
//             control: (baseStyles, state) => ({
//               ...baseStyles,
//               borderColor: "var(--kt-input-border-color)",
//               color: "var(--kt-input-border-color)",
//             }),
//           }}
//         />
//         {props.error && (
//           <div className="form__error">
//             <span>{props.error}</span>
//           </div>
//         )}
//       </div>
//       <div
//         className={
//           props?.parentDivClassName
//             ? `${props?.parentDivClassName} mb-5 d-flex align-items-end`
//             : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5 d-flex align-items-end"
//         }
//       >
//         <button
//           onClick={() => setOpenModal(true)}
//           className="btn btn-light-primary btn-sm px-4 p-2 py-3"
//         >
//           <span className="bi bi-plus "></span> Add
//         </button>
//       </div>
//       {openModal && (
//         <Popup
//           openModal={openModal}
//           setOpenModal={setOpenModal}
//           modalheader="Add Insurance Provider"
//         >
//           <div className="row">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label className="required mb-2">Provider Name</label>
//                 <input
//                   type="text"
//                   name="providerName"
//                   className="form-control bg-transparent"
//                   placeholder="Provider Name"
//                 />
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label className="required mb-2">Provider Code</label>
//                 <input
//                   type="text"
//                   name="providerCode"
//                   className="form-control bg-transparent"
//                   placeholder="Provider Code"
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label className="required mb-2">Address 1</label>
//                 <input
//                   type="text"
//                   name="address1"
//                   className="form-control bg-transparent"
//                   placeholder="Address 1"
//                 />
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label className="required mb-2">City</label>
//                 <input
//                   type="text"
//                   name="city"
//                   className="form-control bg-transparent"
//                   placeholder="City"
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label className="required mb-2">Zip Code</label>
//                 <input
//                   type="text"
//                   name="zipCode"
//                   className="form-control bg-transparent"
//                   placeholder="Zip Code"
//                 />
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="fv-row mb-4">
//                 <label htmlFor="status" className="mb-2">
//                   State
//                 </label>
//                 <Select
//                   options={stateDropdownArray}
//                   placeholder="Select State"
//                   theme={(theme) => styles(theme)}
//                   isSearchable={true}
//                   styles={{
//                     control: (baseStyles, state) => ({
//                       ...baseStyles,
//                       borderColor: "var(--kt-input-border-color)",
//                       color: "var(--kt-input-border-color)",
//                     }),
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="col-12">
//               <div className="d-flex card-footer mt-3 py-3 px-0 align-items-center justify-content-start">
//                 <button
//                   onClick={() => setOpenModal(false)}
//                   type="button"
//                   className="btn btn-secondary"
//                 >
//                   Cancel
//                 </button>
//                 <button type="button" className="btn btn-primary m-2">
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         </Popup>
//       )}
//     </>
//   );
// };

// export default ReactSelect;
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { stateDropdownArray, styles } from "../../../Utils/Common";
import InsuranceService from "../../../Services/InsuranceService/InsuranceService";
import Popup from "../../../Shared/Modal/ViewRequisitionPopup";
import { assignFormValues } from "../../../Utils/Auth";

const ReactSelect = (props: any) => {
  const {
    Inputs,
    dependenceyControls,
    index,
    depControlIndex,
    fieldIndex,
    inputValue,
    isDependency,
    repeatFieldSection,
    isDependencyRepeatFields,
    repeatFieldIndex,
    repeatDependencySectionIndex,
    repeatDepFieldIndex,
    ControlType,
  } = props;

  const [dropdownOpts, setDropdownOpts] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const fetchedOptions = useRef(false);
  useEffect(() => {
    if (ControlType === "InsuranceProviderControl" && !fetchedOptions.current) {
      getOptions();
    }
  }, [ControlType]);

  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      inputElementReactSelect.current.focus();
    }
  }, [props?.errorFocussedInput]);

  const getOptions = async () => {
    fetchedOptions.current = true;
    try {
      const res = await InsuranceService.GetInsuranceProvidersDropdown(0);
      setDropdownOpts(res?.data);
      const selected = res?.data?.find(
        (x: any) => x?.value === parseInt(inputValue)
      );
      if (selected?.value !== 0) {
        setSelectedOption(selected);
        assignFormValues(
          Inputs,
          dependenceyControls,
          index,
          depControlIndex,
          fieldIndex,
          selected?.value,
          isDependency,
          repeatFieldSection,
          isDependencyRepeatFields,
          repeatFieldIndex,
          repeatDependencySectionIndex,
          repeatDepFieldIndex,
          selectedOption,
          props?.setInputs
        );
      }
    } catch (err) {
      console.error("Error fetching insurance providers", err);
    }
  };

  useEffect(() => {
    setPrefilledValue();
  }, [inputValue, dropdownOpts]);

  const setPrefilledValue = () => {
    const selected = inputValue
      ? dropdownOpts?.find((x: any) => x.value === parseInt(inputValue))
      : "";
    setSelectedOption(selected);
  };
  const getInsuranceType = (inputs: any): any => {
    for (const item of inputs) {
      if (item.sectionName === "Billing Information") {
        for (const field of item.fields) {
          if (field.displayFieldName === "Repeat Start") {
            return parseValue(field?.defaultValue);
          }
        }
      }
    }
    return null;
  };

  const parseValue = (value: any): any => {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.error("Error parsing JSON:", e);
        return value.trim();
      }
    }
    return value;
  };
  const validationSchema = Yup.object({
    providerName: Yup.string().required("Provider Name is required"),
    providerCode: Yup.string().required("Provider Code is required"),
    address1: Yup.string().required("Address 1 is required"),
    address2: Yup.string(),
    city: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "City must contain only alphabetic characters")
      .required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string()
      .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits")
      .required("Zip Code is required"),
  });
  const formik = useFormik({
    initialValues: {
      providerName: "",
      providerCode: "",
      address1: "",
      city: "",
      zipCode: "",
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        providerName: values.providerName || null,
        providerCode: values.providerCode || null,
        address1: values.address1 || null,
        city: values.city || null,
        state: values.state || null,
        zipCode: values.zipCode || null,
        insuranceId: localStorage.getItem("insurnceID"),
        insuranceType: getInsuranceType(props?.Inputs)?.BillingType,
      };

      try {
        await InsuranceService.addPatientInsuranceProvider(payload);
        setOpenModal(false);
        formik.resetForm();
        getOptions();
      } catch (err) {
        console.error("Error saving insurance provider", err);
      }
    },
  });

  return (
    <>
      <div id={props?.name} ref={inputElementReactSelect} tabIndex={-1}></div>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-5`
            : "col-lg-6 col-md-6 col-sm-12 mb-5"
        }
      >
        <label
          className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
        >
          {props?.label}
        </label>
        <Select
          options={dropdownOpts}
          placeholder={props?.label}
          theme={(theme) => styles(theme)}
          value={selectedOption}
          onChange={(e: any) => {
            setSelectedOption(e);
            assignFormValues(
              Inputs,
              dependenceyControls,
              index,
              depControlIndex,
              fieldIndex,
              e.value,
              isDependency,
              repeatFieldSection,
              isDependencyRepeatFields,
              repeatFieldIndex,
              repeatDependencySectionIndex,
              repeatDepFieldIndex,
              e.label,
              props?.setInputs
            );
          }}
          isSearchable={props?.isSearchable}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: "var(--kt-input-border-color)",
              color: "var(--kt-input-border-color)",
            }),
          }}
        />
        {props.error && (
          <div className="form__error">
            <span>{props.error}</span>
          </div>
        )}
      </div>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-5 d-flex align-items-end`
            : "col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5 d-flex align-items-end"
        }
      >
        <button
          onClick={() => setOpenModal(true)}
          className="btn btn-light-primary btn-sm px-4 p-2 py-3"
        >
          <span className="bi bi-plus "></span> Add
        </button>
      </div>
      {openModal && (
        <Popup
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalheader="Add Insurance Provider"
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label className="required mb-2">Provider Name</label>
                  <input
                    type="text"
                    name="providerName"
                    className="form-control bg-transparent"
                    placeholder="Provider Name"
                    onChange={formik.handleChange}
                    value={formik.values.providerName}
                  />
                  {formik.errors.providerName && (
                    <div className="form__error">
                      <span>{formik.errors.providerName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label className="required mb-2">Provider Code</label>
                  <input
                    type="text"
                    name="providerCode"
                    className="form-control bg-transparent"
                    placeholder="Provider Code"
                    onChange={formik.handleChange}
                    value={formik.values.providerCode}
                  />
                  {formik.errors.providerCode && (
                    <div className="form__error">
                      <span>{formik.errors.providerCode}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label className="required mb-2">Address 1</label>
                  <input
                    type="text"
                    name="address1"
                    className="form-control bg-transparent"
                    placeholder="Address 1"
                    onChange={formik.handleChange}
                    value={formik.values.address1}
                  />
                  {formik.errors.address1 && (
                    <div className="form__error">
                      <span>{formik.errors.address1}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label className="required mb-2">City</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control bg-transparent"
                    placeholder="City"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                  />
                  {formik.errors.city && (
                    <div className="form__error">
                      <span>{formik.errors.city}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label htmlFor="status" className="mb-2 required">
                    State
                  </label>
                  <Select
                    options={stateDropdownArray}
                    placeholder="Select State"
                    theme={(theme) => styles(theme)}
                    isSearchable={true}
                    onChange={(option) =>
                      formik.setFieldValue(
                        "state",
                        option ? option.value : null
                      )
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "var(--kt-input-border-color)",
                        color: "var(--kt-input-border-color)",
                      }),
                    }}
                  />
                  {formik.errors.zipCode && (
                    <div className="form__error">
                      <span>{formik.errors.state}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="fv-row mb-4">
                  <label className="required mb-2">Zip Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    className="form-control bg-transparent"
                    placeholder="Zip Code"
                    onChange={formik.handleChange}
                    value={formik.values.zipCode}
                  />
                  {formik.errors.zipCode && (
                    <div className="form__error">
                      <span>{formik.errors.zipCode}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex card-footer mt-3 py-3 px-0 align-items-center justify-content-start">
                  <button
                    onClick={() => {
                      setOpenModal(false);
                      formik.resetForm();
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary m-2">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Popup>
      )}
    </>
  );
};

export default ReactSelect;

export const NavigatorsArray = [
  {
    text: "Home",
    link: "#",
  },
  {
    text:"Lis Setup",
    link:"#"
  },
  {
    text:"Compendium Data",
    link:"#"
  },
];

import { TableCell } from "@mui/material";

function NoRecord({ message, colSpan }: { message?: string, colSpan?:number }) {
  return (
    <TableCell colSpan={colSpan ?? 9}>
      <div className="text-center d-flex align-items-center gap-2 justify-content-center">
        <i className="bi bi-inbox h1 m-0"></i>
        <span className="fw-bolder">
          {message ?? "No data found for this table"}
        </span>
      </div>
    </TableCell>
  );
}

export default NoRecord;

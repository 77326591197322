import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InsuranceService from "../../Services/InsuranceService/InsuranceService";
import { SortingTypeI } from "../../Utils/consts";
import usePagination from "../hooks/usePagination";

type Context = {
  loadData: any;
  loadDataShipment: any;
  setCourierName: Dispatch<SetStateAction<string>>;
  courierName: string;
  rows: any;
  setRows: any;
  shipment: any;
  setShipment: any;
  setSearchRequestShipment: any;
  setSearchRequestShipmentTracking: any;
  searchRequestShipment: any;
  searchRequestShipmentTracking: any;
  setSearchRequest: any;
  searchRequest: any;
  initialSearchQuery: any;
  initialSearchQueryShipment: any;
  initialSearchQueryShipmentTracking: any;
  setSorting: any;
  sortById: any;
  setPageSize: any;
  sort: any;
  searchRef: any;
  handleSort: any;
  pageSize: any;
  curPage: any;
  total: any;
  showPage: any;
  prevPage: any;
  pageNumbers: any;
  nextPage: any;
  totalPages: any;
  loading: boolean;
  loadDataShipmentTracking: any;
  setShipmentTracking: any;
  shipmentTracking: any;
  setTabs: any;
  tabs: any;
  setCurPage:any
};

const context = createContext<Context>({} as Context);
export default function CourierContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [courierName, setCourierName] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [rows, setRows] = useState<any>(() => []);
  const [shipment, setShipment] = useState<any>(() => []);
  const [shipmentTracking, setShipmentTracking] = useState<any>(() => []);
  const initialSearchQuery = {
    courierName: courierName,
    contactName: "",
    companyName: "",
    address: "",
    address2: "",
    city: "",
    stateName: "",
    zipCode: "",
    phoneNumber: "",
    pickupDate: null,
    startPickupTime: null,
    endPickupTime: null,
    packagesCount: 0,
    remarks: "",
    dispatchConfirmationNo: "",
    location: "",
    labName: "",
    isDeleted: false,
  };
  const initialSearchQueryShipment = {
    courierName: courierName,
    accountName: "",
    senderName: "",
    senderCompanyName: "",
    senderAddress: "",
    senderAddress2: "",
    senderCity: "",
    senderStateName: "",
    senderZipCode: "",
    senderPhoneNumber: "",
    recipentName: "",
    recipentCompanyName: "",
    recipentAddress: "",
    recipentAddress2: "",
    recipentCity: "",
    recipentStateName: "",
    recipentZipCode: "",
    recipentPhoneNumber: "",
    shipmentDate: null,
    packageType: "",
    serviceType: "",
    packagesCount: null,
    packageWeight: null,
    packageDescription: "",
    schedulePickup: false,
    pickupDate: null,
    startPickupTime: null,
    endPickupTime: null,
    remarks: "",
    trackingNumber: "",
    status: "",
    labName: "",
    isDeleted: false,
  };
  const initialSearchQueryShipmentTracking = {
    facilityName: "",
    trackingNumber: "",
    dateofScan: null,
    status: "",
    isDeleted: false,
    courierName: courierName,
  };
  let [searchRequest, setSearchRequest] = useState(initialSearchQuery);
  let [searchRequestShipment, setSearchRequestShipment] = useState(
    initialSearchQueryShipment
  );
  let [searchRequestShipmentTracking, setSearchRequestShipmentTracking] =
    useState(initialSearchQueryShipmentTracking);
  const sortById = {
    clickedIconData: "Id",
    sortingOrder: "desc",
  };
  const [sort, setSorting] = useState<SortingTypeI>(sortById);
  const searchRef = useRef<any>(null);
  const handleSort = (columnName: any) => {
    searchRef.current.id = searchRef.current.id
      ? searchRef.current.id === "asc"
        ? (searchRef.current.id = "desc")
        : (searchRef.current.id = "asc")
      : (searchRef.current.id = "asc");

    setSorting({
      sortingOrder: searchRef?.current?.id,
      clickedIconData: columnName,
    });
  };
  //============================================================================================
  //====================================  PAGINATION START =====================================
  //============================================================================================
  const {
    curPage,
    pageSize,
    total,
    totalPages,
    pageNumbers,
    nextPage,
    prevPage,
    showPage,
    setPageSize,
    setTotal,
    setCurPage,
  } = usePagination();

  //============================================================================================
  //====================================  PAGINATION END =======================================
  //============================================================================================
  const loadData = (loader: boolean, reset: boolean, sortingState?: any) => {
    if (!courierName) {
      return false;
    }
    setLoading(true);
    InsuranceService.ShippingAndScheduleAllData({
      pageNumber: curPage,
      pageSize: pageSize,
      queryModel: reset
        ? { ...initialSearchQuery, isDeleted: tabs === 0 ? false : true }
        : { ...searchRequest, isDeleted: tabs === 0 ? false : true },
      sortColumn: reset ? sortingState?.clickedIconData : sort?.clickedIconData,
      sortDirection: reset ? sortingState?.sortingOrder : sort?.sortingOrder,
    })
      .then((res: any) => {
        setRows(res?.data?.data);
        setTotal(res?.data?.total);
        setLoading(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
        setLoading(false);
      });
  };
  const loadDataShipment = (
    loader: boolean,
    reset: boolean,
    sortingState?: any
  ) => {
    if (!courierName) {
      return false;
    }
    setLoading(true);
    InsuranceService.ShippingAndScheduleGetAllShipment({
      pageNumber: curPage,
      pageSize: pageSize,
      queryModel: reset
        ? {
            ...initialSearchQueryShipment,
            isDeleted: tabs === 1 ? false : true,
          }
        : { ...searchRequestShipment, isDeleted: tabs === 1 ? false : true },
      sortColumn: reset ? sortingState?.clickedIconData : sort?.clickedIconData,
      sortDirection: reset ? sortingState?.sortingOrder : sort?.sortingOrder,
    })
      .then((res: any) => {
        setShipment(res?.data?.data);
        setTotal(res?.data?.total);
        setLoading(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
        setLoading(false);
      });
  };
  const loadDataShipmentTracking = (
    loader: boolean,
    reset: boolean,
    sortingState?: any
  ) => {
    if (!courierName) {
      return false;
    }
    setLoading(true);
    InsuranceService.ShippingAndScheduleGetAllShipmentTracking({
      pageNumber: curPage,
      pageSize: pageSize,
      queryModel: reset
        ? {
            ...initialSearchQueryShipmentTracking,
            isDeleted: tabs === 2 ? false : true,
          }
        : {
            ...searchRequestShipmentTracking,
            isDeleted: tabs === 2 ? false : true,
          },
      sortColumn: reset ? sortingState?.clickedIconData : sort?.clickedIconData,
      sortDirection: reset ? sortingState?.sortingOrder : sort?.sortingOrder,
    })
      .then((res: any) => {
        setShipmentTracking(res?.data?.data);
        setTotal(res?.data?.total);
        setLoading(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
        setLoading(false);
      });
  };
  useEffect(() => {
    setSearchRequest(initialSearchQuery);
    setSearchRequestShipment(initialSearchQueryShipment);
    setSearchRequestShipmentTracking(initialSearchQueryShipmentTracking);
  }, [tabs]);

  return (
    <context.Provider
      value={{
        setCurPage,
        loadData,
        loadDataShipment,
        setCourierName,
        courierName,
        shipment,
        setShipmentTracking,
        setSearchRequestShipmentTracking,
        shipmentTracking,
        setShipment,
        rows,
        setRows,
        setSearchRequest,
        setSearchRequestShipment,
        loadDataShipmentTracking,
        searchRequestShipment,
        searchRequestShipmentTracking,
        initialSearchQueryShipmentTracking,
        initialSearchQueryShipment,
        initialSearchQuery,
        searchRequest,
        setSorting,
        sortById,
        setPageSize,
        sort,
        searchRef,
        handleSort,
        pageSize,
        curPage,
        total,
        showPage,
        prevPage,
        pageNumbers,
        nextPage,
        totalPages,
        loading,
        setTabs,
        tabs,
      }}
    >
      {children}
    </context.Provider>
  );
}

export const useCourierContext = () => useContext(context);
